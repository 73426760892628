import PageHeader from '../../components/pageHeader/PageHeader';
import LeadForm from '../../components/leadForm/LeadForm';
import Card from '../../components/card/Card';
import './wizard.css';
export default function WizardPage() {
  // require('./wizard.css')
  return (
    <div className='wizard'>
      <PageHeader
        title='ברוכים הבאים לסימולטור!'
        description='רוצים ללמוד יותר על התוכניות שלנו? לתכנן יחד את חתונות הילדים?'
        extra='הסימולטור שלנו יעשה בשבילכם את כל החישובים, עד שתהיה לכם תמונה ברורה ומדויקת של ההלוואות, התרומות והמענקים למשפחה שלכם.' />
      <Card>
        <LeadForm />
        <div className="illustration-01-wrapper">
          <img className="illustration-01-img" alt="תמונת אווירה" src="./images/illustration-01.png" />
        </div>
      </Card>
    </div>
  )
}
