import React, { useEffect } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import Terms from '../../components/terms/Terms'

export default function TermsPage() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = 'תקנון'
        return () => {
            document.title = prevTitle
        }
    }, [])


    return (
        <>
            <PageHeader title='תקנון' />
            <Terms />
        </>
    )
}
