import { useState, useContext, useRef, memo } from 'react';
import Button from '../buttons/Button';
import SummaryHeader from './SummaryHeader';
import SummaryTotalSlider from './SummaryTotalSlider';
import SummaryDetails from './SummaryDetails';
import { simulatorContext } from '../../service/SimulatorContext';
import { appContext } from '../../service/AppContext';

const Summary = memo(() => {

    const { user, isPending, setFetch, navigate, urlAgent } = useContext(appContext)
    const { summaryKids, completedKids } = useContext(simulatorContext);
    const [openDrawer, setOpenDrawer] = useState(false)

    const touchY = useRef(null)
    const summaryDrawerRef = useRef(null)
    const summaryInnerDrawerRef = useRef(null)

    const showDrawer = !!completedKids.length

    const toggleDrawer = () => {
        setOpenDrawer(prev => !prev);
        summaryInnerDrawerRef.current.scrollTo(0, 0);
    }

    const handleDragStart = (e) => {
        touchY.current = e.clientY

    }

    const handleDrag = (e) => {
        if (!e.clientY) {
            return
        }
        if (openDrawer && summaryInnerDrawerRef.current.contains(e.target))
            return
        if (!openDrawer && touchY.current > e.clientY) {
            setOpenDrawer(true)
        }
        if (openDrawer && touchY.current < e.clientY) {
            setOpenDrawer(false)
            summaryInnerDrawerRef.current.scrollTo(0, 0);
        }
    }

    const handleSubmit = () => {
        if (!isPending) {
            setFetch({
                route: '/unit-request',
                options: {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        id: user?.id,
                        urlAgent,
                        kids: completedKids?.map(kid => { return { ...kid, plan: kid.plan?.route, planName: kid.plan?.title } })
                    })
                }
            })
            navigate('/registration', { state: { user, completedKids } })
        }

    }
    return (
        <section ref={summaryDrawerRef} className={`summary ${(openDrawer) ? 'show-mobile-summary' : ''}`} draggable="false" onDragStart={handleDragStart} onDrag={handleDrag} onTouchStart={(e) => handleDragStart(e.changedTouches[0])} onTouchMove={(e) => handleDrag(e.changedTouches[0])}>
            <SummaryHeader totalKids={completedKids?.length} className={(showDrawer) ? 'show-drawer' : ''} openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
            <div ref={summaryInnerDrawerRef} className={`summary-drawer ${(showDrawer) ? 'show-drawer' : ''}`}  >
                <div className="totals-wrapper" >
                    <SummaryTotalSlider id="total-monthly-donation" label='סה"כ תרומה חודשית' units={summaryKids?.totalUnits} value={summaryKids?.totalMonthlyDonation} max={completedKids?.length * 8} showUnits={true} onClick={toggleDrawer} disabled={!showDrawer} />
                    <SummaryTotalSlider id="total-loans" label='סה"כ ההלוואות' units={summaryKids?.totalUnits} value={summaryKids?.totalLoan} max={completedKids?.length * 8} disabled={!showDrawer}/>
                </div>
                <SummaryDetails openDrawer={ openDrawer} />
            </div>
            <div className="summary-button-wrapper">
                <Button onClick={handleSubmit} className="wide-button gold-button" icon='check-circle' label='המשך' />
            </div>
        </section>
    )
});

export default Summary;