import { useContext } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import SummaryItemReturn from './SummaryItemReturn'
import ReturnsChart from '../charts/ReturnsChart';
// import FamilyReturnsChart from '../charts/FamilyReturnsChart'
import SummaryWaitLoan from './SummaryWaitLoan';
import { appContext } from '../../service/AppContext';
// import SummaryItemReducedReturn from './SummaryItemReducedReturn';
export default function SummaryReturnsTab({ className = '' }) {
    const { tab, timeLine, completedKids, summaryKids } = useContext(simulatorContext);

    const { ranges } = timeLine;

    const summaryItems = ranges?.map((range, idx) => {      
        if (range.activeLoans || range.activeDonations)
            return <SummaryItemReturn key={idx} {...range} amount={completedKids.length} />
        return <SummaryWaitLoan key={idx} {...range} />
    }
    )

    return (
        <div className={`${className} ${(tab === 1) ? 'show-flex' : ''}`} id="returns-tab">            
                <ReturnsChart ranges={ranges} label='החזר חודשי ממוצע לכל התקופה' />
            {summaryItems}
        </div>
    )
}
