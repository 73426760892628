import { useContext } from 'react';
import { ganttContext } from './TimelineGantt';
import GanttInfoDetailsRow from './GanttInfoDetailsRow';
export default function TimelineInfo({ kid, top }) {

  const { setFocusedUnit } = useContext(ganttContext);

  const { name, plan, units } = kid;

  let grantPercent = Math.round(plan.return * 100 / plan.totalPay);

  let above = (top > 300)

  const handleKeyDown = (e) => {
    if (typeof (setFocusedUnit) !== 'function') return
    if ([13, 32, 27].includes(e.keyCode))
      setFocusedUnit(null);       
  }

  return (
    <div className={`gantt-info ${(above) ? 'gantt-info-top' : ''}`}>
      <div className="gantt-info-wrapper" >
        <div className="gantt-info-header">
          <img src="./icons/ellipse-blue.svg" alt='' />
          <span className='header-label'>{name}</span>
        </div>
        <img className='remove-item' src="./icons/x-mark.svg" alt='סגירה' onClick={(e) => { setFocusedUnit(null) }} tabIndex={0} onKeyDown={handleKeyDown} />
      </div>
      <div className="gantt-info-details">
        <GanttInfoDetailsRow label="חודשי תרומה" type="text" value={plan?.monthsSave} />
        {/* <GanttInfoDetailsRow label="שנות תרומה" type="text" value={Math.round(plan?.monthsSave / 12)} /> */}
        <GanttInfoDetailsRow label='סך התרומה' value={plan?.totalPay * units} />
        <GanttInfoDetailsRow label="גובה הלוואה" value={plan?.loan * units} />
        {/* <GanttInfoDetailsRow label="גובה מענק" type='percent' value={grantPercent} /> */}
        < GanttInfoDetailsRow label="סכום מענק" value={plan?.return * units} />
        <GanttInfoDetailsRow label="החזר חודשי" value={plan?.monthlyReturn * units} />
      </div>
    </div>
  )
}
