import { useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { appContext } from './AppContext';

export const registrationContex = createContext();

export const initialKid = { name: '', personId: '', birthday: '', plan: null, preBorn: false, age: null, units: 0 }


export default function RegistrationContex({ children }) {


  const location = useLocation()

  const { user, plans, data } = useContext(appContext)

  const [donor, setDonor] = useState({ mobile: '', sector: '', isMember: '', ...user, ...location.state?.user })

  const [kids, setKids] = useState((location.state?.completedKids && location.state?.completedKids.length) ? location.state?.completedKids : [{ ...initialKid, id: uuidv4() }]);

  const [signed, setSigned] = useState({ joining: false, joiningSignature: null, interest: false, interestSignature: null })

  // Update donor on user update
  useEffect(() => {
    setDonor(prev => ({ ...prev, ...user }))
  }, [user])

  // Update kids on createdLines or continue Lead kids 
  useEffect(() => {
    if (data?.createdLines)
      setKids(prev => prev.map(kid => (Object.keys(data.createdLines).includes(kid.id)) ? { ...kid, requestLine: data.createdLines[kid.id] } : kid))

    if (data?.lines && !location.state?.completedKids)// Continue Lead only when no simulator state Kids
      setKids(data.lines.map(kid => ({ ...initialKid, id: uuidv4(), ...kid, plan: (kid.plan || kid.plan === 0) ? plans?.find(plan => plan.route === kid.plan) : null })))

    if (data?.signed)
      setSigned(data.signed)


  }, [data, plans, location])


  // Summary kids 
  const completedKids = kids?.map(kid => kid).sort((a, b) => b.age - a.age)?.reduce((acc, kid) => {
    if (kid.name && kid.age !== undefined && kid.plan && kid?.units > 0) {
      if (kid.plan.totalPay === kid.plan.return) {
        let modifyKid = JSON.parse(JSON.stringify(kid));
        //callculate kid grant  for rest of units (50 /66 %)
        modifyKid.plan.return = (kid.plan.monthsSave >= 192) ? kid.plan.return * 0.66 : kid.plan.return * 0.5;
        //first kid get compleate unit grant
        if (!acc.grantFirstUnit) {
          modifyKid.plan.return = (kid.plan.return + modifyKid.plan.return * (kid.units - 1)) / kid.units;
          modifyKid.grantFirstUnit = true;
        }
        acc.grantFirstUnit = true;
        acc.kids.push(modifyKid);
      }
      else
        acc.kids.push(kid);
    }
    return acc
  }, { kids: [], grantFirstUnit: false }).kids

  const summaryKids = completedKids?.reduce((acc, kid) => {
    acc.totalUnits += Number(kid?.units);
    acc.totalMonthlyDonation += kid?.units * kid?.plan?.monthlyPay;
    acc.totalDonation += kid?.units * kid?.plan?.totalPay;
    acc.totalMonthlyReturn += kid?.units * kid?.plan?.monthlyReturn;
    acc.totalLoan += kid?.units * kid?.plan?.loan //units * 40000; 
    acc.totalReturn += kid?.units * kid?.plan?.return;
    return acc
  }, { totalUnits: 0, totalMonthlyDonation: 0, totalLoan: 0, totalDonation: 0, totalReturn: 0, totalMonthlyReturn: 0 });

  const value = { donor, setDonor, kids, setKids, completedKids, summaryKids, signed, setSigned };

  return (
    <registrationContex.Provider value={value} >
      {children}
    </registrationContex.Provider >
  )
}
