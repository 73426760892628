import GanttInfoDetailsRow from './GanttInfoDetailsRow';


import { monthFullName, simulatorContext } from '../../service/SimulatorContext';
import { useContext } from 'react';
import { ganttContext } from './TimelineGantt';
import { toGregorianDate, toHebrewJewishDate } from 'jewish-date';

export default function TimelineTikInfo({ month, year, tickRef, jewishDate }) {

  const { timeLine } = useContext(simulatorContext);

  const { bgRect } = useContext(ganttContext);

  const { ranges } = timeLine;

  const getHeader = () => {
    if (jewishDate) {
      const hebrewDate = toHebrewJewishDate({ year, monthName: month, day: 1 })
      return hebrewDate.monthName + '\xa0' + hebrewDate.year;
    }
    return monthFullName[month - 1] + '\xa0' + year;
  }

  const header = getHeader();

  const currentDate = jewishDate ? toGregorianDate({ year, monthName: month, day: 1 }) : new Date(year, month - 1, 1)

  const range = ranges.find(range => range.startDate <= currentDate && range.endDate >= currentDate)


  const left = ((bgRect?.width - tickRef.current?.offsetLeft) > 280)

  return (
    <div className="hover-line" >
      <div className={`bg-info gantt-info ${(left) ? '' : 'bg-info-left'}`}>

        <div className="gantt-info-wrapper" >
          <div className="gantt-info-header">
            <img src="./icons/ellipse-cyan.svg" alt='' />
            <span className='header-label'>{header}</span>
          </div>
        </div>
        <div className="gantt-info-details">
          <GanttInfoDetailsRow label="תרומות פעילות" type="text" value={(range) ? range.activeDonations : 0} />
          <GanttInfoDetailsRow label="הלוואות פעילות" type="text" value={(range) ? range.activeLoans : 0} />
          <GanttInfoDetailsRow label='סה"כ החזר חודשי' value={(range) ? range.monthlyDonation + range.actualMonthlyReturn : 0} />

        </div>
      </div>
    </div>
  )
}
