import { useContext } from 'react'
import TabSelector from '../buttons/TabSelector';
import { simulatorContext } from '../../service/SimulatorContext';
import SummaryDontationTab from './SummaryDontationTab';
import SummaryReturnsTab from './SummaryReturnsTab';

export default function SummaryDetails({ openDrawer }) {

    const { tab, setTab } = useContext(simulatorContext);


    const tabsOptions = [
        { label: "תרומות והלוואות", id: "mobile-monthly-donation-button", onClick: () => { setTab(0) } },
        { label: "החזר חודשי", id: "mobile-monthly-return-button", onClick: () => { setTab(1) } }
    ]

    return (
        <div className="summary-details">
            <TabSelector mobile={true} tabs={tabsOptions} current={tab} tabIndex={(openDrawer) ? 0 : -1} />
            {/* <!-------------- Summary Donation Tab --------------> */}
            <SummaryDontationTab />
            {/* <!-------------- Summary Returns Tab --------------> */}
            <SummaryReturnsTab />
        </div>
    )
}
