import { useContext, useRef } from "react";
import Button from "../buttons/Button";
import FileUpload from "../input/FileUpload";
import { appContext } from "../../service/AppContext";
import directDebitPDF from './directDebit.pdf'


export default function ManualBankInfoForm({ onCompleted }) {

    const { user, setFetch } = useContext(appContext)

    const formRef = useRef()


    const handleSubmit = (e) => {
        e.preventDefault();

        const formElement = formRef.current;
        const isValid = formElement.checkValidity();

        formElement.classList.add('submitted');

        const firstInvalidField = formElement.querySelector(":invalid");
        firstInvalidField?.focus();

        if (isValid) {
            const dataObject = new FormData(formElement);
            if (user?.id)
                dataObject.append('id', user?.id);
            setFetch({
                route: '/manual-approval', options: {
                    method: 'POST',
                    body: dataObject
                }
            })
            if (typeof (onCompleted) === 'function')
                onCompleted(e);
        }
    }

    return (
        <form ref={formRef} noValidate>
            <div>
                <h4>הנחיות לפתיחת הרשאה בטופס ידני</h4>
                <p>יש להוריד את הטופס למילוי ידני, ולצרף אותו מלא וחתום, ואנחנו נשלח אותו לבנק לאישור.</p>
            </div>
            <div className="download-form">
                <label>1. הורדת הטופס למילוי ידני</label>
                <div className='download-template' >
                    <img src="./icons/download-file.svg" alt='download' />
                    <a href={directDebitPDF} download='טופס הוראת קבע.pdf' >טופס ידני לפתיחת הרשאה</a>
                </div>
            </div>
            <FileUpload label='2. צירוף הטופס החתום' name='attachment' required />

            <Button type='button' onClick={handleSubmit} size='small' label='אישור וסיום' />
        </form>)
}