import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
// import { familyPlanReturnMap } from '../../service/SimulatorContext';
import { formatJewishDateInHebrew, toJewishDate } from 'jewish-date';

const Br = () => "\n";

const fixHebrewWithNumber = (text) => {

    let list = text.trim().split(/(\d+)/);
    return list.map(part => ((/(\d+)/).test(part)) ? part.split("").reverse().join("") : part).join("")
}

export default function PdfTemplate({ user, completedKids, summaryKids }) {

    const grantPercent = Math.round(summaryKids.totalReturn * 100 / summaryKids.totalDonation)

    Font.register({
        family: 'SimplerPro',
        fonts: [
            { src: process.env.PUBLIC_URL + '/fonts/SimplerPro-Regular.woff', fontWeight: 'normal' },
            { src: process.env.PUBLIC_URL + '/fonts/SimplerPro-Bold.woff', fontWeight: 'bold' },
            { src: process.env.PUBLIC_URL + '/fonts/SimplerPro-Semibold.woff', fontWeight: 'semibold' },
        ]
    })
    // Create styles
    const styles = StyleSheet.create({
        page: {
            fontFamily: 'SimplerPro',
            flexDirection: 'column',
            // backgroundColor: '#E4E4E4'
            textAlign: 'right',
            marginHorizontal: 20,
            marginVertical: 10,
        },
        main: {
            width: '94%', height: '100%', position: 'relative'
        },
        logo: { height: 40, width: 160 },
        heading: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            fontSize: 8,
            textAlign: 'left'
        },
        date: { lineHeight: 2 },
        header: { fontWeight: 'bold', fontSize: 18, marginVertical: 20 },
        hr: {
            width: '100%',
            marginVertical: 5,
            borderBottom: 1,
            BorderStyle: 'solid',
            borderColor: '#d9ddec',
        },
        subHeader: { fontWeight: 'bold', fontSize: 12, marginBottom: 5 },
        marketingText: { fontWeight: 'normal', fontSize: 12, marginBottom: 10 },
        planSection: { fontSize: 10, marginVertical: 20 },
        summarySection: {
            fontSize: 10,
            marginTop: 10,
            marginBottom: 20,
            flexDirection: "row-reverse",
            gap: 80
        },
        summaryHeader: {
            fontWeight: 'bold',
            fontSize: 10,
            borderBottom: 1,
            BorderStyle: 'solid',
            borderColor: '#000000',
            lineHeight: 1.2
        },
        donationTable: { width: 200, flexDirection: 'column', },
        tableRow: {
            lineHeight: 1.5,
            marginTop: 10,
            flexDirection: "row-reverse",
            justifyContent: 'space-between',
            borderBottom: 1,
            BorderStyle: 'solid',
            borderColor: '#d9ddec'
        },
        tableLastRow: {
            lineHeight: 1.5,
            marginTop: 10,
            flexDirection: "row-reverse",
            justifyContent: 'space-between',
        },
        tableValueCell: {
            fontWeight: 'bold'
        },
        kidsSection: {
            marginTop: 5,
            width: '100%',
            // border: 1,
            BorderStyle: 'solid',
            borderColor: '#d9ddec',
            textAlign: 'right',
            fontSize: 10,
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        kidsTableHeader: {
            lineHeight: 1.5,
            flexDirection: "row-reverse",
            BorderStyle: 'solid',
            borderColor: '#d9ddec',
            backgroundColor: '#ebeef9',
            fontSize: 8,
            flexGrow: 1
        },
        kidsTableRow: {
            lineHeight: 1.5,
            flexDirection: "row-reverse",
            BorderStyle: 'solid',
            borderColor: '#d9ddec',
            flexGrow: 1
        },
        tableColName: { width: 100, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColAge: { width: 35, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColPlan: { width: 110, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColMarriage: { width: 70, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColMounths: { width: 80, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColYears: { width: 70, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColDeposit: { width: 80, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColWait: { width: 115, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableColLoan: { width: 130, borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor: '#d9ddec' },
        tableCell: {
            paddingTop: 5,
            paddingRight: 5,
            // paddingLeft: 5,
            borderColor: '#d9ddec'
        },
        footer: { position: 'absolute', width: '100%', bottom: 30, fontSize: 10 },
        rowReverse: { flexDirection: 'row-reverse', gap: 5 },
        rowReverseLoan: { flexDirection: 'row-reverse' },
        rowReverseCenter: { flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center' },
        moreInfo: { color: '#172554' },
        moreInfoBold: { color: '#172554', fontWeight: 'bold' },
        disclaimer: { fontSize: 8, textAlign: 'center', color: '#495157' },
        phoneIcon: { width: 12, height: 12, marginLeft: 5 },
        tableUnits: { fontSize: 8, lineHeight: 1.5 },
        tablePercent: { fontSize: 8, lineHeight: 1.5, verticalAlign: 'sub' },



    })


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.main}>
                    <View style={styles.heading}>
                        <Image src={process.env.PUBLIC_URL + '/images/pdf-logo.jpg'} style={styles.logo} />
                        <Text style={styles.date}>בס"ד<Br /> {new Date().toLocaleString().split(',')[0]} | {formatJewishDateInHebrew(toJewishDate(new Date()))}</Text>
                    </View>
                    <View >
                        <Text style={styles.header}>תוצאות סימולטור תרומות והלוואות</Text>
                        {(!!user.firstName || !!user.lastName) &&
                            <Text style={styles.subHeader} >{user.firstName + ' ' + user.lastName} לכבוד: </Text>
                        }
                        <Text style={styles.marketingText}>כך תחתן את הילדים שלך בכבוד</Text>
                    </View>
                    <View style={styles.hr} />
                    {/* <View style={styles.planSection}>
                        <Text style={styles.subHeader} >{'רגיל'} סוג מסלול: </Text>
                        <Text>משלמים את התרומות ומחזירים את ההלוואות בטווח זמן קצר יותר, ומבקבלים מענקים בהדרגה.</Text>
                    </View> */}
                    <Text style={styles.subHeader} >סיכום התוכניות שלכם</Text>
                    <View style={styles.summarySection}>
                        <View style={styles.donationTable}>
                            <Text style={styles.summaryHeader}>תרומות </Text>
                            <View style={styles.tableRow}>
                                <View >
                                    <Text >סה"כ ילדים</Text>
                                </View>
                                <View >
                                    <Text style={styles.tableValueCell}>{completedKids.length}</Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View >
                                    <Text >סה"כ תרומה חודשית</Text>
                                </View>
                                <View style={styles.rowReverse}>
                                    <Text style={styles.tableUnits}>(יח'&nbsp;{summaryKids.totalUnits})</Text>
                                    <Text style={styles.tableValueCell}>{summaryKids.totalMonthlyDonation.toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                            </View>

                            <View style={styles.tableLastRow}>
                                <View >
                                    <Text >סה"כ תרומה</Text>
                                </View>
                                <View >
                                    <Text style={styles.tableValueCell}>{summaryKids.totalDonation.toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.donationTable}>
                            <Text style={styles.summaryHeader}>הלוואות </Text>
                            {/* <View style={styles.tableRow}>
                                <View >
                                    <Text >סוג המענק</Text>
                                </View>
                                <View >
                                    <Text style={styles.tableValueCell}>'רגיל- בסיום ההלוואה'</Text>
                                </View>
                            </View> */}


                            <View style={styles.tableRow}>
                                <View >
                                    <Text >סה"כ הלוואות</Text>
                                </View>
                                <View >
                                    <Text style={styles.tableValueCell}>{summaryKids.totalLoan.toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                            </View>
                            <View style={styles.tableLastRow}>
                                <View style={styles.rowReverse}>
                                    <Text >סה"כ מענקים</Text>
                                </View>
                                <View >
                                    <Text style={styles.tableValueCell}>{summaryKids.totalReturn.toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.subHeader} >פירוט תוצאות ופרטי ילדים</Text>
                    <View style={styles.kidsSection}>
                        <View style={styles.kidsTableHeader}>
                            <View style={styles.tableColName}>
                                <Text style={styles.tableCell}>שם הילד/ה</Text>
                            </View>
                            <View style={styles.tableColAge}>
                                <Text style={styles.tableCell}>גיל</Text>
                            </View>
                            <View style={styles.tableColPlan}>
                                <Text style={styles.tableCell}>מסלול</Text>
                            </View>
                            <View style={styles.tableColMarriage}>
                                <Text style={styles.tableCell}>גיל נישואים</Text>
                            </View>
                            <View style={styles.tableColMounths}>
                                <Text style={styles.tableCell}>חודשי תרומה</Text>
                            </View>
                            <View style={styles.tableColDeposit}>
                                <Text style={styles.tableCell}>סה"כ תרומות</Text>
                            </View>
                            <View style={styles.tableColYears}>
                                <Text style={styles.tableCell}>גובה המענק</Text>
                            </View>
                            <View style={styles.tableColWait}>
                                <Text style={styles.tableCell}>חודשי המתנה להלוואה</Text>
                            </View>
                            <View style={styles.tableColLoan}>
                                <Text style={styles.tableCell}>סה"כ הלוואה לילד</Text>
                            </View>
                        </View>
                        {completedKids.map((kid, idx) => {
                            let monthsWait = (kid.marriage - kid.age) * 12 - kid.plan?.monthsSave;


                            return (<View key={idx} style={styles.kidsTableRow}>
                                <View style={styles.tableColName}>
                                    <Text style={styles.tableCell}>{fixHebrewWithNumber(kid.name)}</Text>
                                </View>
                                <View style={styles.tableColAge}>
                                    <Text style={styles.tableCell}>{kid.age}</Text>
                                </View>
                                <View style={styles.tableColPlan}>
                                    <Text style={styles.tableCell}>{fixHebrewWithNumber(kid.plan.title)}</Text>
                                </View>
                                <View style={styles.tableColMarriage}>
                                    <Text style={styles.tableCell}>{kid.marriage}</Text>
                                </View>
                                <View style={styles.tableColMounths}>
                                    <Text style={styles.tableCell}>{kid.plan.monthsSave}</Text>
                                </View>
                                <View style={styles.tableColDeposit}>
                                    <Text style={styles.tableCell}>{(kid.plan?.totalPay * kid.units).toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                                <View style={styles.tableColYears}>
                                    <Text style={styles.tableCell}>{(kid.plan?.return * kid.units).toLocaleString('he-IL') + '₪'}</Text>
                                </View>
                                <View style={styles.tableColWait}>
                                    <Text style={styles.tableCell}>{(monthsWait > 0) ? monthsWait : 0}</Text>
                                </View>
                                <View style={styles.tableColLoan}>
                                    <View style={styles.rowReverseCenter}>
                                        <Text style={styles.tableUnits}>(יח'&nbsp;{kid.units})</Text>
                                        <Text style={styles.tableCell}>{(kid.plan?.loan * kid.units).toLocaleString('he-IL') + '₪'}</Text>
                                    </View>
                                </View>
                            </View>)

                        })}


                    </View>

                    <View style={styles.footer}>
                        <View style={styles.rowReverseCenter}>
                            <Image src={process.env.PUBLIC_URL + '/images/phone-icon.png'} style={styles.phoneIcon} />
                            <Text style={styles.moreInfoBold}>למידע נוסף ושיחת טלפון עם נציג |</Text>
                            <Text style={styles.moreInfo}> המרכז הטלפוני פעיל בימים א'-ה' בשעות 00:51-00:90 </Text>
                        </View>
                        <View style={styles.hr} />
                        <Text style={styles.disclaimer}>
                            <View style={styles.rowReverseCenter}>
                                <Text >ידוע לי כי אין באמור כאן משום התחייבות כלשהי וכי הסכומים המאושרים הם אלו שיופיעו בהסכם ההצטרפות הרשמי מטעם הגמ"ח.</Text>
                                <Text>*</Text>
                            </View>
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
