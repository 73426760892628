import React, { useEffect, useRef, useState } from 'react'
import { useFetch } from './useFetch';

export default function useTokenEx({ styles }) {

    const { baseStyle, invalidStyle, focusStyle } = styles;

    const { fetchOptions, data: tokenEx, isPending, error, setFetch } = useFetch('/tokenex');

    const [cardMessage, setCardMessage] = useState();
    const [cvvMessage, setCvvMessage] = useState();

    const iframePCI = useRef()
    const iframeCVV = useRef()
    const inputPciTokenRef = useRef()
    const inputCVVTokenRef = useRef()



    useEffect(() => {

        if (tokenEx) {
            //----------- iframes PCI Config --------------
            var iframeConfigPCI = {
                styles: {
                    base: baseStyle,
                    error: invalidStyle,
                    focus: focusStyle,
                },
                origin: tokenEx.origin,
                timestamp: tokenEx.timestamp,
                tokenExID: tokenEx.token_ex_id,
                tokenScheme: "PCI",
                authenticationKey: tokenEx.pci_auth_key,
                enableValidateOnBlur: true,
                pci: true,
                cvv: false,
                title: 'Credit Card'
            }

            //----------- iframes CVV Config --------------
            var iframeConfigCVV = {
                styles: {
                    base: baseStyle,
                    error: invalidStyle,
                    focus: focusStyle,
                },
                origin: tokenEx.origin,
                timestamp: tokenEx.timestamp,
                tokenExID: tokenEx.token_ex_id,
                tokenScheme: 'nGUID',
                customRegEx: "^\\d{3,4}$",
                pci: false,
                cvv: false,
                title: 'Cvv Number',
                enableValidateOnBlur: true,
                authenticationKey: tokenEx.cvv_auth_key,
            }

            //----------- iframes element DOM Id Listeners --------------
            try {

                iframePCI.current = new TokenEx.Iframe("tokenExIframeDiv", iframeConfigPCI);  // eslint-disable-line
                iframeCVV.current = new TokenEx.Iframe("CvvTextbox", iframeConfigCVV);  // eslint-disable-line
            }
            catch (e) {
                console.error(e);
            }

            //----------- PCI Listeners --------------
            iframePCI.current?.on("error", function (data) { console.log("CC iFrame error:" + JSON.stringify(data)) });
            iframePCI.current?.on("validate", function (data) {
                if (data?.validator === 'required') setCardMessage('שדה חובה');
                if (data?.validator === 'format') setCardMessage('פורמט לא תקין');
                if (data?.isValid) {
                    setCardMessage();
                    iframePCI.current.tokenize()
                }
                else
                    inputPciTokenRef.current.value = ''
            });

            iframePCI.current?.on("tokenize", function (data) {
                if (data.token)
                    inputPciTokenRef.current.value = data.token;
            });

            //----------- CVV Listeners --------------
            iframeCVV.current?.on("error", function (data) { console.log("CVV iFrame error:" + JSON.stringify(data)) });
            iframeCVV.current?.on("validate", function (data) {
                console.log(" iFrame validate:" + JSON.stringify(data))
                if (data?.validator === 'required') setCvvMessage('שדה חובה');
                if (data?.validator === 'customRegEx') setCvvMessage('פורמט לא תקין');
                if (data?.isValid) {
                    setCvvMessage();
                    iframeCVV.current.tokenize()
                }
                else
                    inputCVVTokenRef.current.value = ''
            });

            iframeCVV.current?.on("tokenize", function (data) {
                if (data.token)
                    inputCVVTokenRef.current.value = data.token;

            });
            iframeCVV.current?.on("change", function (data) {
                console.log(" iFrame change:" + JSON.stringify(data))

            });

            iframePCI.current?.on('load', function () {document.getElementById('tx_iframe_tokenExIframeDiv').title = 'Credit Card';})
            iframeCVV.current?.on('load', function () {document.getElementById('tx_iframe_CvvTextbox').title = 'Cvv Number';})
            //----------- iframes Initialization --------------
            iframePCI.current?.load();
            iframeCVV.current?.load();
        }

        return () => {
            //----------- iframes Cleanup --------------
            iframePCI.current?.remove()
            iframeCVV.current?.remove()

        }


    }, [tokenEx, baseStyle, focusStyle, invalidStyle])

    function TokenExFormElement() {
        return <>
            <input ref={inputCVVTokenRef} className="required-invisible" name="cvv-token" id="cvv-token" required hidden onInvalid={() => { iframeCVV.current?.focus() }} />
            <input ref={inputPciTokenRef} className="required-invisible" name="card-token" id="card-token" required hidden onInvalid={() => { iframePCI.current?.focus() }} />
        </>
    }


    return { cardMessage, cvvMessage, TokenExFormElement }
}

