import PageHeader from '../../components/pageHeader/PageHeader'
import SimulatorMobileMenu from '../../components/simulator/SimulatorMobileMenu'
import SimulatorSubHeader from '../../components/simulator/SimulatorSubHeader'
import MonthlyDonationTab from '../../components/simulator/MonthlyDonationTab'
import MonthlyReturnTab from '../../components/simulator/MonthlyReturnTab'
import Summary from '../../components/summary/Summary'
import SimulatorContext from '../../service/SimulatorContext'
import './simulator.css'




export default function SimulatorWrapper() {
  return (
    <SimulatorContext>
      <SimulatorPage />
    </SimulatorContext>
  )
}

export function SimulatorPage() {
  return (
    <div className='simulator'>
      <PageHeader title={"סימולטור תרומות והלוואות"} wrapper={true}>
        <SimulatorMobileMenu />
      </PageHeader >
      <div className="frame-grid">
        <SimulatorSubHeader />
        <MonthlyDonationTab />
        <MonthlyReturnTab />
        <Summary />
      </div>
    </div>
  )
}

