import {utils,write} from "xlsx-js-style";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (apiData, fileName) => {
    // debugger;
    const wb = { Workbook: { Views: [{ RTL: true }] }, Sheets: {}, SheetNames: [] };
    apiData?.forEach(sheet => {

        if (wb.SheetNames.includes(sheet.name)) {
            utils.sheet_add_json(wb.Sheets[sheet.name], sheet.data, sheet.options)
            for (const cell in wb.Sheets[sheet.name]) {
                if (!["!ref", "!cols"].includes(cell))
                    wb.Sheets[sheet.name][cell].s = {...wb.Sheets[sheet.name][cell].s, alignment: { horizontal: "right" } };
            }

        }
        else {
            const ws = utils.json_to_sheet(sheet.data, sheet.options);
            wb.Sheets[sheet.name] = ws;
            wb.SheetNames.push(sheet.name);
            ws["!cols"] = sheet.cols;
            for (const cell in ws) {
                if (!["!ref", "!cols"].includes(cell))
                    ws[cell].s = {...ws[cell].s, alignment: { horizontal: "right" } };
                if (sheet.Heading) utils.sheet_add_aoa(ws, sheet.Heading);
            };
        }

    });
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    downloadFile(data, fileName + fileExtension, fileType);
};


function downloadFile(content, filename, fileType) {
    // Create a blob
    var blob = new Blob([content], { type: fileType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}