import React from 'react'

export default function PlanDetailRow({ preLabel, label, value, type = 'text', reverse = false, children }) {

    const rowMap = {
        currency: <><span className="shekel-mrk">₪</span>{value?.toLocaleString('he-IL')}{children}</>,
        percent: <>{value?.toLocaleString('he-IL')}<span className="shekel-mrk">%</span>{children}</>,
        text: <>{children}{value}</>
    }
    const parsedPreLabel = (preLabel) ? preLabel + ' ' : '';

    return (
        <div className="plan-detail-row">
            <img src="./icons/check-circle-gold.svg" alt='✓' />
            {(reverse) ?
                <span>{(parsedPreLabel)}{rowMap[type]}&nbsp;{label}</span>
                :
                <span>{parsedPreLabel}{label}&nbsp;{rowMap[type]}</span>
            }
        </div>
    )
}
