import { useEffect, useRef, useState, memo, useContext } from 'react'
import Input from '../input/Input'
import Select from '../select/Select'
import PlanSelect from '../select/PlanSelect'
import SelectUnits from '../select/SelectUnits'
import PlanSelectPopup from '../planSelectPopup/PlanSelectPopup'
import { minimizedAgeOptions } from '../../service/inputUtil'
import { appContext } from '../../service/AppContext'
import DatePicker from '../datePicker/DatePicker'
import Checkbox from '../input/Checkbox'
import { dateToAge } from '../../service/dateUtil'

const SimulatorChildCard = memo(({ index = 0, id, name, age, plan, marriage, units, updateCB, removeKid, preBorn, birthday, removeDisabled = false }) => {

  const { urlAgent } = useContext(appContext)

  const [minimize, setMinimze] = useState(false)
  const [openPopup, setOpenPopup] = useState(false) //Popup state
  const cardRef = useRef(true)
  const inputRef = useRef()
  const PlanWithPrefix = (plan && age >= 1 && age <= 3) ? 'מרכזית - ' + plan?.title : plan?.title;


  const handleMinimize = (e) => {
    setMinimze(prev => !prev)
  }

  useEffect(() => {
    cardRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
    }, 400);

    return () => {
      if (timeoutId)
        clearTimeout(timeoutId)
    }
  }, [])

  const handleUpdateAge = (e) => {
    if (typeof (updateCB) === 'function')
      updateCB(id, 'age')(e);
    if (e <= 3)
      setOpenPopup(true)
  }

  const handleUpdateBirthday = (e) => {
    if (typeof (updateCB) === 'function')
      updateCB(id, 'birthday')(e);
    if (dateToAge(e) <= 3)
      setOpenPopup(true)
    else if (openPopup && (!dateToAge(e) || dateToAge(e) > 16))
      setOpenPopup(false)

  }


  const handleUpdatePreBorn = (value) => {
    if (value)
      setOpenPopup(true)
    if (typeof (updateCB) === 'function')
      updateCB(id, 'preBorn')(value)
  }

  const handleKeyDown = (e) => {
    if (typeof (handleMinimize) !== 'function') return
    if ([13, 32].includes(e.keyCode)) {
      e.preventDefault();
      handleMinimize(e)
    }
  }

  return (
    <div ref={cardRef} className="child-card" >
      <div className="title-wrapper">
        <div>
          <span className="child-index">{index + 1}.</span>
          {(name) ? <span className="child-title">{name}</span> : <span className="child-title gray-title">שם הילד</span>}
        </div>
        <img className={`card-minimize ${(minimize) ? 'minimized' : ''}`} src="./icons/chevron-up.svg" alt='הקטנת כרטיס' onClick={handleMinimize} tabIndex={0} onKeyDown={handleKeyDown} />
      </div>
      <hr className="card-hr-desktop" />

      {/* <!-- Start Cards  --> */}
      <div className="card-content-wrapper">

        <div className="card-inner">
          <div className="fields-flex">
            <Input myRef={inputRef} label="שם הילד/ה" className="child-name" name="child-name" placeholder="שם פרטי של הילד/ה" value={name} onChange={updateCB(id, 'name')} />
          </div>
          <div className="fields-flex">
            <Select className="child-age" name="child-age" label="גיל" value={age} options={minimizedAgeOptions} onChange={handleUpdateAge} />
            {(urlAgent.enabledBirthDay) &&
              <div className='birthday-wrapper'>
                <DatePicker type={(preBorn) ? 'text' : 'date'} label="תאריך לידה" className="child-birthday" name="child-birthday" value={(preBorn) ? 'הילוד עדיין לא נולד' : birthday} onChange={handleUpdateBirthday} disabled={preBorn} max={new Date().toJSON().slice(0, 10)} >
                </DatePicker>
              </div>
            }
            <PlanSelect className={`child-plan ${(name) ? '' : 'desktop-or-wide'}`} label="מסלול" tooltip="לכל גיל מסלול שונה. אנא בחרו את המסלול המתאים לכם." value={PlanWithPrefix}
              setOpenPopup={setOpenPopup} disabled={(age==null ||  dateToAge(birthday) >16)} required={true} errorText="יש לבחור מסלול" OnValueButtonLabel={(age > 3) && 'פרטים'} />
            {(openPopup) && <PlanSelectPopup closePopup={() => setOpenPopup(false)} setPlan={updateCB(id, 'plan')} name={name} age={age} setAge={updateCB(id, 'age')} />}
            <Select className={`child-marriage ${(name) ? '' : 'desktop-or-wide'}`} name="child-marriage" label="גיל נישואים צפוי" tooltip=".כדי שנוכל להתאים לכם תוכנית מדויקת לחתונה של הילד/ה, יש להעריך את גיל הנישואין העתידי" value={marriage} onChange={updateCB(id, 'marriage')}
              options={[18, 19, 20, 21, 22, 23, 24]} />
          </div>
        </div>
      </div>
      <hr className={`card-hr-mobile`} style={(marriage) ? {} : { display: 'none' }} />
      <div className={`card-sum ${(marriage) ? '' : 'desktop-or-wide'}`}  >
        <SelectUnits value={units} onChange={updateCB(id, 'units')} />
      </div>
      <button className="remove-item align-center" onClick={(e) => { removeKid(id) }} disabled={removeDisabled}><img src="./icons/x-mark.svg" alt='הסר' />הסרה מרשימה</button>
    </div>
  )
})
export default SimulatorChildCard