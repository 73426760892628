
export default function GanttInfoDetailsRow({ label, value, type = 'currency', children }) {

    const rowMap = {
        currency: <><span className="shekel-mrk">₪</span>{value?.toLocaleString('he-IL')}{children}</>,
        percent: <>{value?.toLocaleString('he-IL')}<span className="shekel-mrk">%</span>{children}</>,
        strikeCurrency: <><s className='strike'><span className="shekel-mrk">₪</span>{value?.toLocaleString('he-IL')}</s><span className="shekel-mrk">₪</span>{children?.toLocaleString('he-IL')}</>,
        strike: <><s className='strike'>{value}</s>{children}</>,
        text: <>{children}{value}</>
    }


    return (
        <div className="gantt-details-row">
            <span className="label">{label}</span>
            <span className="value">
                {rowMap[type]}
            </span>
        </div>
    )
}
