import React from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfTemplate from './PdfTemplate';
import { useContext } from 'react';
import { simulatorContext } from '../../service/SimulatorContext';
import { appContext } from '../../service/AppContext';

export default function ExportPdf() {
    const { completedKids, summaryKids } = useContext(simulatorContext)
    const {  user } = useContext(appContext)

    return (

        <div className="pdf-wrapper align-center"  >
            <PDFDownloadLink document={<PdfTemplate user={user} completedKids={completedKids} summaryKids={summaryKids}  />} fileName="סימולטור_תרומות_והלוואות.pdf">
                <img src="./icons/pdf.svg" alt='pdf' style={{ margin: "4px" }} />יצוא ל-PDF
            </PDFDownloadLink >
        </div>
    )
}
