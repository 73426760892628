import { useState, useMemo, createContext, useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { timeLineAccumulator } from './timelineUtils';
import useLocalStorage from './useLocalStorage';
import { appContext } from './AppContext';
import { useLocation } from 'react-router-dom';
// DEV STUB
// import plans from './db.json'
// import stub from './stub.json'
// import { useFetch } from './useFetch';

// Genarate Context Provider
export const simulatorContext = createContext();

const initialKid = { name: '', age: null, plan: null, marriage: null, units: 0, preBorn: false, birthday: '' }

export const familyPlanReturnMap = { 1: 0, 2: 0, 3: 0.75, 4: 0.76, 5: 0.78, 6: 0.75, 7: 0.76 }

export const monthName = ["ינו'", "פבר'", "מרץ", "אפר'", "מאי", "יוני", "יולי", "אוג'", "ספט'", "אוק'", "נוב'", "דצמ'"];
export const monthFullName = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

export const planAgeMapper = {
  0: {
    ageLabel: "זוגות צעירים לאחר הנישואין ועד חצי שנה מהלידה",
    header: 'תכנית הצעירים',
    plans: [208, 209]
  },
  0.5: {
    ageLabel: "זוגות צעירים לאחר הנישואין ועד חצי שנה מהלידה",
    header: 'תכנית הצעירים',
    plans: [208, 209],
  },
  1: {
    ageLabel: "גיל 0-3",
    header: 'התוכנית המרכזית',
    plans: [0, 21],
  },
  2: {
    ageLabel: "גיל 0-3",
    header: 'התוכנית המרכזית',
    plans: [0, 21],
  },
  3: {
    ageLabel: "גיל 0-3",
    header: 'התוכנית המרכזית',
    plans: [0, 21],
  },
  default: {
    ageLabel: "גיל 3-16",
    header: 'תוכנית רב גילאית',
    plans: [121, 137, 210, 211, 212, 213, 214, 194]
  },

}


export default function SimulatorContext({ children }) {
  // Donation / Retuns Tab state managment
  const [tab, setTab] = useState(0);
  // // Fetch plans from Backend
  // const { data: plans } = useFetch('/plans');

  const { user, navigate, setUser, urlAgent, data, plans } = useContext(appContext)

  const location = useLocation();


  const [kids, setKids, attemptUseStoredValue] = useLocalStorage(user?.email, [{ ...initialKid, id: uuidv4() }]);

  const orderdKids = [];

  // Case no user - Check for location state user & local storage kids
  useEffect(() => {
    if (!user.email || !user.firstName || !user.lastName) {
      if (location?.state?.user) {
        setUser(location.state.user)
        if (location.state.user?.email)
          attemptUseStoredValue(location.state.user.email)

      }
      // Case no location state - check if Agent or continue Lead
      else if (!urlAgent.agent && !urlAgent.mainAgent && !urlAgent.lead)
        navigate('/')
    }
  }, [user, navigate, location, setUser, attemptUseStoredValue, urlAgent]);

  // Update kids on continue Lead kids
  useEffect(() => {
    if (data?.lines)
      setKids(data.lines.map(kid => ({ ...initialKid, id: uuidv4(), ...kid, plan: (kid.plan || kid.plan === 0) ? plans?.find(plan => plan.route === kid.plan) : null })))

  }, [data, plans, setKids])


  // Handle add kid button state
  const addKidDisabled = kids?.some(kid => !(kid.name && kid.age && kid.plan && kid.marriage && kid?.units > 0));

  // Handle Add kid button action
  const handleAddKid = () => {
    setKids(prev => [...prev, { ...initialKid, id: uuidv4() }])
  };
  // Handle reset kids cards
  const handleResetKids = () => {
    setKids([{ ...initialKid, id: uuidv4() }])
  };

  // Summary kids 
  const completedKids = kids?.map(kid => kid).sort((a, b) => b.age - a.age)?.reduce((acc, kid) => {
    if (kid.age && kid.plan && kid.marriage && kid?.units > 0) {
      if (kid.plan.totalPay === kid.plan.return) {
        let modifyKid = JSON.parse(JSON.stringify(kid));
        //callculate kid grant  for rest of units (50 /66 %)
        modifyKid.plan.return = (kid.plan.monthsSave >= 192) ? kid.plan.return * 0.66 : kid.plan.return * 0.5;
        //first kid get compleate unit grant
        if (!acc.grantFirstUnit) {
          modifyKid.plan.return = (kid.plan.return + modifyKid.plan.return * (kid.units - 1)) / kid.units;
          modifyKid.grantFirstUnit = true;
        }
        acc.grantFirstUnit = true;
        acc.kids.push(modifyKid);
      }
      else
        acc.kids.push(kid);
    }
    return acc
  }, { kids: [], grantFirstUnit: false }).kids


  // Donations & Loans summary accumulator
  const summaryKids = completedKids?.reduce((acc, kid) => {
    acc.totalUnits += Number(kid?.units);
    acc.totalMonthlyDonation += kid?.units * kid?.plan?.monthlyPay;
    acc.totalDonation += kid?.units * kid?.plan?.totalPay;
    acc.totalReturn += kid?.units * kid?.plan?.return;
    acc.totalLoan += kid?.units * kid?.plan?.loan //units * 40000; 
    acc.familyTotalReturn += kid.familyGrant;
    return acc
  }, { totalUnits: 0, totalMonthlyDonation: 0, totalLoan: 0, totalDonation: 0, totalReturn: 0, familyTotalReturn: 0 });


  const timeLine = useMemo(() => timeLineAccumulator(completedKids), [completedKids]);

  // Handle summary units slider
  const HandleChangeSummaryDonationSlider = (value) => {
    let childUnits = Math.round(value / completedKids?.length);
    setKids(prev =>
      prev.map(kid =>
        (kid.name && kid.age && kid.plan && kid?.marriage > 0) ? { ...kid, units: childUnits } : kid))
  };

  const value = {
    kids, setKids,
    handleResetKids, handleAddKid,
    completedKids, timeLine, addKidDisabled, summaryKids,
    tab, setTab,
    HandleChangeSummaryDonationSlider,
    // checkReciveGrant,
    orderdKids
  }

  return (
    <simulatorContext.Provider value={value}>
      {children}
    </simulatorContext.Provider>
  )
}
