import React from 'react'
import Card from '../card/Card'
import './accessibilityDisclaimer.css'
import Button from '../buttons/Button'
import { useNavigate } from 'react-router-dom'

export default function AccessibilityDisclaimer() {

    const navigate = useNavigate();
    
    
    const handleBack = (e) => {
        navigate(-1);
    }

    return (
        <Card className='accessibility-disclaimer'>
            <p>הזירה האינטרנטית הנה פלטפורמה לביטוי וייצוג עצמי, היא משמשת אותנו כזירה חברתית ופוליטית.<br />
                אנו רוכשים ומוכרים, עובדים ונחשפים באמצעותה כיום יותר מבעבר.</p>
            {/* <br /> */}
            <p>וככזו ישנה מחויבות לאפשר לציבור חווית גלישה מהנה וקלה ככל האפשר.</p>
            <p>אנו משקיעים משאבים רבים להפוך אתר זה לנגיש בכדי לאפשר את חווית הגלישה לכלל האוכלוסייה ולאנשים עם מוגבלויות בפרט.</p>
            <p>המוטו שמוביל אותנו הנו כבוד האדם וחירותו,שכן מדובר באבן יסוד בחברה הישראלית כי כולנו שווי זכויות ושווים במהותנו.</p>
            <h2>לנוחותכם הסבר על דרכי הגלישה באתר באמצעות מקלדת :</h2>
            <ol>
                <li>לצורך הפעלת האתר באמצעות מקלדת - גולשים המתקשים בהפעלת עכבר יכולים לגלוש באתר באמצעות מקלדת.<br /> לחיצה על מקש ה- "Tab" תעביר בין האלמנטים השונים בעמוד ולחיצה על מקש ה- "Enter" תבצע לחיצה על האלמנט.</li>
                <li>ניתן לחזור בין אלמנטים על-ידי לחיצה בו זמנית על המקשים "Shift" ו- "Tab".</li>
                <li>לצורך הגדלת התצוגה באתר - ניתן להגדיל את התצוגה באתר על-ידי לחיצה בו זמנית על המקשים "Ctrl" ו- "+".</li>
                <li>כדי להקטין את התצוגה יש ללחוץ על המקשים "Ctrl" ו- "-". </li>
                <li>ניתן לסגור חלונית POPUP על-ידי לחיצה על מקש "Esc"</li>
            </ol>
            <h2>להלן הסדרי הנגישות למשרדי החברה הממוקמים בית אורנים כנפי נשרים, ירושלים:</h2>
            <ol>
                <li>במתחם החניה ובחניון של בניין המשרדים ישנן חניות נכים נגישות.</li>
                <li>הדרך לבניין המשרדים נגישה לאנשים עם מוגבלות.</li>
                <li>דלת הכניסה למשרד נגישה לאנשים עם מוגבלות.</li>
                <li>ניתן להגיע אל המשרד בעזרת מעלית נגישה.</li>
                <li>המעברים במשרד הינם מרווחים ומאפשרים התניידות קלה לאנשים המסתייעים בכיסא גלגלים.</li>
                <li>בבניין המשרדים ישנם תאי שירותים לנכים.</li>
            </ol>
            <h2>פרטי רכז נגישות:</h2>
            <p>החברה מינתה את אלנתן פאר לרכז הנגישות של החברה.</p>
            <p>לבירורים נוספים, שאלות או בקשות מיוחדות בנושא נגישות האתר ונגישות משרדי החברה<br />
                ניתן ליצור קשר באמצעים הבאים:</p>
            <p><strong>שם:</strong> אלנתן פאר</p>
            <p><strong>טלפון : </strong>02-6767513</p>
            <p><strong>דואר אלקטרוני</strong>: <a href='mailto:ep@glb.org.il'>ep@glb.org.il</a></p>
            <p className='small'>הצהרת הנגישות עודכנה בתאריך 05/06/2024</p>
            <br/>
            <Button label="חזרה" onClick={handleBack} />
        </Card>
    )
}
