import React, { useMemo } from 'react'
import PlanDetails from './PlanDetails'
import { planAgeMapper } from '../../service/SimulatorContext'
export default function PlansCard({ plans, handleSetPlan, age }) {

  // Dynamic Plan Options for Each kid base on Plan max age & kid age
  const planAgeHeader = planAgeMapper[(age > 3) ? 'default' : age]
  const plansDetails = plans?.filter(plan => planAgeHeader?.plans?.includes(plan.route))
    .sort((a, b) => a.route - b.route).sort((a, b) => a.age - b.age)
    .filter((plan, idx) => (age > 3) ? (plan.age === age || idx === 0) : true)
    .map(plan => <PlanDetails key={plan.route} plan={plan} handleSetPlan={handleSetPlan} />)

  return (
    <div className='plan-card'>
      <div className='card-header'>
        <p className='paln-label'>{planAgeHeader?.ageLabel}</p>
        <h3 className='plan-type'>{planAgeHeader?.header}</h3>
      </div>
      <div className='plans-wrapper'>
        {plansDetails}
      </div>
    </div>
  )
}
