import { useRef, Fragment } from 'react'
import TimelineItem from './TimelineItem';
import { toJewishDate } from 'jewish-date';


export default function TimelineContent({ ready, units, years, jewishDate }) {

    let ganttContentRef = useRef()

    let aTop = 0;

    let ranges = units.map(unitToTimeline)


    function unitToTimeline(unit) {
        aTop += 60;
        let startLocation = getMonthLocation(unit.donation.startDate)
        let endLocation = getMonthLocation(unit.donation.endDate);
        // let width = endLocation - startLocation;
        let loanStartLocation = getMonthLocation((unit?.familyGrant) ? unit.familyLoan.startDate : unit.loan.startDate)
        let loanEndLocation = getMonthLocation((unit?.familyGrant) ? unit.familyLoan.endDate : unit.loan.endDate)
        // let loanWidth = loanEndLocation - loanStartLocation;
        return (
            <Fragment key={unit.id}>
                <TimelineItem left={startLocation.left} right={endLocation.right} top={aTop} value={unit.plan.monthlyPay * unit.units} label="תרומה חודשית" kid={unit} />
                <TimelineItem left={loanStartLocation.left} right={loanEndLocation.right} loan={true} name={unit.name} top={aTop} value={Math.round(unit.plan.monthlyReturn * unit.units * 10) / 10} label="החזר חודשי" kid={unit} />
            </Fragment>)
    }



    function toDateClass(year, month) {
        return 'd-' + month + '-' + year;
    }

    function dateToDateClass(date) {
        let year, month;
        if (jewishDate) {
            date = toJewishDate(date);
            year = date.year;
            month = date.monthName;
        }
        else {
            year = date.getFullYear();
            month = date.getMonth() + 1;
        }
        return toDateClass(year, month);
    }

    function getMonthLocation(date) {

        let ganttRect = ganttContentRef.current?.getBoundingClientRect()
        let dateClass = '.' + dateToDateClass(date);
        let line = document.querySelector(dateClass);
        let lineRect = line?.getBoundingClientRect();
        let left = (lineRect?.right - ganttRect?.left) * 100 / ganttRect?.width;
        let right = (ganttRect?.right - lineRect?.left) * 100 / ganttRect?.width;
        return { left, right };
    }

    return (
        <div ref={ganttContentRef} className="gantt-content">
            {(ready && years === ready) && ranges}
        </div>)
}
