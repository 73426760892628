import { useState, useEffect, useCallback } from "react";

const server = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DEV_BACKEND : process.env.REACT_APP_PROD_BACKEND


export const useFetch = (route, options) => {


  const [fetchOptions, innerSetFetch] = useState({ route, options });
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {

    const abortController = new AbortController();

    const fetchData = async () => {
      setIsPending(true);
      try {
        const response = await fetch(((fetchOptions?.server) ? fetchOptions.server : server) + fetchOptions.route, {
          ...fetchOptions?.options,
          headers: {
            ...fetchOptions?.options?.headers,
            "Accept": "application/json"
          }, signal: abortController.signal
        });
        if (!response.ok) throw new Error(response?.msg);
        const json = await response.json();
        setIsPending(false);
        setData(json);
        setError(null);
      } catch (error) {
        if (abortController.signal.aborted) return
        console.error(`${error} Could not Fetch Data `);
        setError(error);
        setIsPending(false);
      }
    };

    if (fetchOptions?.route) fetchData();

    return () => abortController.abort();

  }, [fetchOptions]);

  const setFetch = useCallback((obj) => {
    setError(null);
    innerSetFetch(obj);
  }, [])


  return { fetchOptions, data, isPending, error, setFetch };
};