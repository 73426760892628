import React from 'react'
import { useState } from 'react'

export default function PlanExpandableDetails({ title, children }) {
    const [minimize, setMinimze] = useState(false)

    const handleMinimize = (e) => {
        setMinimze(prev => !prev)
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            setMinimze(prev => !prev)
        }
    }

    return (
        <div className="details-item-wrapper" onClick={handleMinimize} tabIndex={0} onKeyDown={handleKeyDown}>
            <div className="details-item" >
                <span className="details-item-header">{title}</span>
                <img className={`item-minimize ${(minimize) && 'minimized'}`} src="./icons/chevron-up.svg" alt='⌃' />
            </div>
            <div className="details-rows-wrapper">
                {children}
            </div>
        </div>
    )
}
