import Card from '../../components/card/Card';
import PageHeader from '../../components/pageHeader/PageHeader';
import CardSteps from '../../components/cardSteps/CardSteps';
import ChildrenRegistration from '../../components/registrationChildren/RegistrationChildren';
import { useContext, useEffect, useState } from 'react';
import './registration.css';
import DonorDetailsRegistration from '../../components/registrationDonorDetails/RegistrationDonorDetails';
import { registrationContex } from '../../service/RegisrationContext';
import PaymentMethod from '../paymentMethod/PaymentMethod';
import RegistrationCompleted from '../registrationCompleted/RegistrationCompleted';
import { appContext } from '../../service/AppContext';


export default function Registration() {

  const [currentStep, setCurrentStep] = useState(0)
  const { summaryKids } = useContext(registrationContex)
  const { fetchOptions, error } = useContext(appContext)
  
  // Handle Fetch Errors
  useEffect(() => {
    if (error && ['/unit-request', '/bank-info','/credit-card-info','/manual-approval'].includes(fetchOptions?.route)) {
      setCurrentStep(prev => (prev > 0) ? prev - 1 : prev)
    }
  }, [fetchOptions?.route, error])

  let dymanicStep = <></>
  if (currentStep === 0)
    dymanicStep = <ChildrenRegistration onCompleted={() => setCurrentStep(1)} />
  if (currentStep === 1)
    dymanicStep = <DonorDetailsRegistration onCompleted={() => setCurrentStep(2)} />
  if (currentStep === 2)
    dymanicStep = <PaymentMethod monthlyDonation={summaryKids?.totalMonthlyDonation} onCompleted={() => setCurrentStep(3)} />
  if (currentStep === 3)
    dymanicStep = <RegistrationCompleted />

  return (
    <div className='registration'>
      <PageHeader
        title='תהליך הצטרפות אונליין'
        description='מצטרפים עכשיו לגמ"ח המרכזי בתהליך דיגיטלי פשוט' />
      <Card>
        <CardSteps steps={['הילדים שלי', 'פרטי ההורה/התורם', 'אמצעי תשלום']} currentStep={currentStep} />
        {dymanicStep}
      </Card>
    </div>
  )
}
