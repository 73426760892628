
import { useContext } from 'react';
import RegisterChildCard from '../registerChildCard.jsx/RegisterChildCard';
import Button from '../buttons/Button';
import { registrationContex } from '../../service/RegisrationContext'
import useUpdateKidCB from '../../service/useUpdateKidCB';

export default function RegistrationChildren({ onCompleted }) {

    const { kids } = useContext(registrationContex)


    const addKidDisabled = kids?.some(kid => kid?.personIdValidation?.invalid || !(kid.name && kid.personId && kid.plan && kid.age !== null && kid?.units > 0));

    //update kid fields via CallBack fn 
    const { addKid, updateKidCB, removeKid } = useUpdateKidCB(useContext(registrationContex))

    //Child Cards to render with dynamic plans options 
    const childCards = kids?.map((kid, index) => <RegisterChildCard key={kid.id} index={index} {...kid} updateCB={updateKidCB} removeKid={removeKid} />)

    return (
        <div>
            <div role="heading" aria-level="2" className='heading-h3'>מלאו את פרטי הילדים שתרצו לצרף לגמ"ח</div>
            <div className="cards-wrapper">
                {childCards}
                <Button size='small' color={'white-button'} className='add-child-button' disabled={addKidDisabled} onClick={addKid} icon="plus-circle" label='הוסף ילד/ה' />
            </div>
            <Button onClick={onCompleted} label="סיימתי למלא את כל פרטי הילדים" disabled={addKidDisabled} />
        </div>
    )
}

