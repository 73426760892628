import React from 'react'
import Card from '../card/Card'
import './privacyPolicy.css'
import Button from '../buttons/Button'
import { useNavigate } from 'react-router-dom'

export default function PrivacyPolicy() {

    const navigate = useNavigate();

    const handleBack = (e) => {
        navigate(-1);
    }

    return (
        <Card className='terms'>
            <h2>מדיניות פרטיות הגמ"ח המרכזי שע"י עמותת גמ"ח לבית בישראל ע.ר. 580403533</h2>     
            <br/>
            <ol>
                <li>בעת ההרשמה או ביצוע פעולות ותשלומים באתר, נאסוף מידע אישי בסיסי כגון שם מלא, כתובת דוא"ל ופרטי התקשרות. בנוסף, נאסוף מידע פיננסי רגיש כמו פרטי כרטיסי אשראי ופרטי חשבונות בנק לצורך ביצוע תשלומים.</li>
                <li>המידע האישי ישמש לצרכי התקשרות, כולל שליחת חומרי פרסום והסברה הקשורים לגמ"ח המרכזי.</li>
                <li>פרטי התשלום ישמשו אך ורק לצורך עיבוד תשלומים באופן מאובטח ולא יחולקו לצדדים שלישיים שאינם קשורים לתהליך התשלום. יובהר כי מלבד העברת פרטי התשלום לצדדים שלישיים הקשורים בתהליך התשלום לצורך עיבודו, לא ישותף את המידע האישי או הפיננסי של המשתמש עם גורמים אחרים ללא הסכמת המשתמש.</li>
                <li>הגמ"ח המרכזי פועל בהתאם לנוהל אבטחת מידע ונוהל ניהול מאגרי מידע של עמותת גמ"ח לבית בישראל ע.ר. 580403533. במסגרת זו הגמ"ח המרכזי נוקט באמצעי אבטחה מתקדמים כגון הצפנה כדי להגן על המידע האישי והפיננסי מפני גישה בלתי מורשית, גניבה או אובדן. הפרטים הפיננסיים מועברים ומאוחסנים בצורה מוצפנת בהתאם לתקני אבטחת מידע מקובלים.</li>
                <li>יובהר כי המשתמש יכול לבקש גישה, עדכון או מחיקה של המידע האישי בכל עת. כמו כן, המשתמש יכול להסיר את עצמו מרשימות התפוצה לחומרי הפרסום וההסברה. יובהר כי הסרת הפרטים בהתאם לסעיף זה יכולה להיות מותנה בביטול החברות בגמ"ח.</li>
                <li>מדיניות זו עשויה להתעדכן מעת לעת. מומלץ לבדוק אותה באופן שוטף.</li>
                <li>אם יש לך שאלות נוספות, אנא צור עמנו קשר באמצעות הדוא"ל: <a href='mailto:feedback@glb.org.il'>feedback@glb.org.il</a></li>
                <li>מדיניות הפרטיות מנוסחת בלשון זכר מטעמי נוחות בלבד אולם כל האמור בלשון זכר משמעו גם בלשון נקבה.</li>                
            </ol>
            <br />
            <Button label="חזרה" onClick={handleBack} />
        </Card>
    )
}
