import { useContext, useRef } from "react";
import Button from "../buttons/Button";
import FileUpload from "../input/FileUpload";
import Input from "../input/Input";
import Select from "../select/Select";
import { appContext } from "../../service/AppContext";

export default function BankInfoForm({ onCompleted, banks }) {

    // const [bankInfo,setBankInfo]
    const { user, setFetch } = useContext(appContext)

    const formRef=useRef()


    const handleSubmit = (e) => {
        e.preventDefault();

        const formElement = formRef.current;
        const isValid = formElement.checkValidity();

        formElement.classList.add('submitted');

        const firstInvalidField = formElement.querySelector(":invalid");
        firstInvalidField?.focus();

        if (isValid) {
            const dataObject = new FormData(formElement);
            if (user?.id)
                dataObject.append('id', user?.id);
            setFetch({
                route: '/bank-info', options: {
                    method: 'POST',
                    body: dataObject
                }
            })
            if (typeof (onCompleted) === 'function')
                onCompleted(e);
        }
    }

    return (
        <form ref={formRef} noValidate>
            <div>
                <h4>הנחיות לפתיחת הרשאה באופן עצמאי בבנק</h4>
                <ul>
                    <li>יש לפתוח הרשאה לא מוגבלת בסכום או בתאריך, לקוד מוסד 38717 - הגמ״ח לבית ישראל.</li>
                    <li>יש למלא את פרטי הבנק ולצרף אסמכתא של הקמת הו״ק</li>
                </ul>
            </div>
            <div className='bank-info-fields'>
                <div className="fields-flex">
                    <Select label='שם ומספר בנק' name='bank' required>
                        {banks?.bank_options?.map(bank => <option key={bank.value} value={bank.value} >{bank.label}</option>)}
                    </Select>
                    <Select label='שם ומספר סניף' name='branch' required>
                        {banks?.branch_options?.map(bank => <option key={bank.value} value={bank.value} >{bank.label}</option>)}
                    </Select>
                </div>
                <Input label='שם בעל החשבון ' name='owner-name' required />
                <FileUpload label='צירוף אסמכתא של הקמת הו"ק' name='attachment' required />
            </div>
            <Button type='button' onClick={handleSubmit} size='small' label='אישור וסיום' />
        </form>)
}