import React from 'react'
import Button from '../buttons/Button'
import PlanExpandableDetails from './PlanExpandableDetails'
import PlanDetailRow from './PlanDetailRow'

export default function PlanDetails({ plan, handleSetPlan }) {

    return (
        <div className='plan-details'>
            <div className="title-wrapper">
                <h3>מסלול&nbsp;{(plan?.title) ? plan.title : plan.name}</h3>
                {(plan.route === 214) && <span className='plan-note'>* עד 4 שנים לפני החתונה</span>}
                <p className='sub-header'>{plan?.description}</p>
            </div>
            <Button color='white-button' label='בחירת מסלול' onClick={(e) => handleSetPlan(plan)} />
            <div className='expandable-details-wrapper'>
                <PlanExpandableDetails title='מה תורמים'  >
                    <PlanDetailRow label={"ליחידה"} value={plan?.monthlyPay} type='currency' reverse={true} />
                    <PlanDetailRow label={"עד 8 יחידות לילד/ה"} />

                    <PlanDetailRow label={"תשלומים"} value={plan?.monthsSave} reverse={true} />
                </PlanExpandableDetails>
                <PlanExpandableDetails title='מה מקבלים' >
                    <PlanDetailRow label={"הלוואה עד"} value={plan?.loan * 8} type='currency' />
                    <PlanDetailRow preLabel="החזר ב-" label={"תשלומים"} value={Math.round(plan.loan / plan.monthlyReturn)} reverse={true} />
                    {(plan.return === plan.totalPay) ?
                        <PlanDetailRow label={"מענק עד"} value={plan.return * 8 * ((plan.monthsSave >= 192) ? 0.666666666 : 0.5)} type='currency' />
                        :
                        <PlanDetailRow label={"מענק עד"} value={plan.return * 8} type='currency' />
                    }
                </PlanExpandableDetails>
                <PlanExpandableDetails title='למי זה מתאים?' >
                    <PlanDetailRow label={plan?.suitableFor} />
                </PlanExpandableDetails>
            </div>
            <div className='more-details' >
                <img src="./icons/download-file.svg" alt='download' />
                <a href={'./images/plans/' + plan?.route + '.jpg'} download>פרטים נוספים על המסלול</a>
            </div>
        </div>
    )
}
