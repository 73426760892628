
export default function SummaryHeader({ totalKids, className, openDrawer, toggleDrawer }) {

  const handleKeyDown = (e) => {
    if (typeof (toggleDrawer) !== 'function') return
    if ([13, 32].includes(e.keyCode)) {
      toggleDrawer(e)
    }
  }

  return (
    <div className={`summary-header ${className}`} onClick={toggleDrawer}>
      <div className="rectangle-mobile" tabIndex={0} onKeyDown={handleKeyDown}></div>
      <div className={`summary-title-wrapper ${(openDrawer) ? 'show-drawer' : ''}`}>
        <span className="summary-minimize"><img src="./icons/wchevron-down.svg" alt='minimize' /></span>
        <span className="drawer-title">סיכום התוכניות שלכם</span>
        <span className="total-kids">
          {(totalKids) ?
            <>סה"כ&nbsp;<span id="total-kids">{totalKids}</span>&nbsp;ילדים</> :
            <>עדיין לא הוספת ילדים</>}
        </span>
      </div>
    </div>)
}
