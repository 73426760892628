import React, { useContext } from 'react'
import RegistrationChildRow from '../kidsTableChildRow/KidsTableChildRow'
import { registrationContex } from '../../service/RegisrationContext'
import './registrationKidsTable.css'

export default function RegistrationKidsTable() {

    const { completedKids } = useContext(registrationContex)

    const kidsRows = completedKids?.map(kid => <RegistrationChildRow key={kid.id} kid={kid} />)

    return (
        <table className='registration-kids-table'>
            <thead>
                <tr className='header-row'>
                    <th>שם הילד/ה</th>
                    <th>תעודת זהות</th>
                    <th>תאריך לידה</th>
                    <th>מסלול/תוכנית</th>
                    <th>כמות יחידות</th>
                    <th>תרומה חודשית</th>
                    <th>תשלומים</th>
                    <th>סה"כ תרומה</th>
                    <th>החזר חודשי</th>
                    <th>תשלומים</th>
                    <th>סה"כ הלוואה</th>
                    <th>מענק</th>
                </tr>
            </thead>
            <tbody>
                {kidsRows}
            </tbody>
        </table>
    )
}
