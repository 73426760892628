import { useContext, useRef } from "react";
import { ganttContext } from "./TimelineGantt";
import TimelineTikInfo from './TimelineTikInfo';



export default function TimelineTick({ year, month, jewishDate }) {

    const tickRef = useRef();

    const { focusedUnit } = useContext(ganttContext);

    function toDateClass(year, month) {
        let dateClass = 'bg-line ';
        dateClass += 'd-' + month + '-' + year;
        if (!focusedUnit)
            dateClass += ' hover-tik';
        if (month === 6 || month === 'Nisan')
            dateClass += ' first-month-year ';
        return dateClass;
    }
    let className = toDateClass(year, month);

    return (
        <div className="tickWrapper">
            {(!focusedUnit) && <TimelineTikInfo month={month} year={year} tickRef={tickRef} jewishDate={jewishDate} />}
            <div ref={tickRef} className={className} />
        </div>)
}
