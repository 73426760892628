import React from 'react'

export default function Step({ current = false, completed = false, children, onClick, ...other }) {

    const handleKeyDown = (e) => {
        if (typeof(onClick) !=='function') return
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            onClick(e)
        }
    }

    return (
        <div className={`step ${(current) ? 'checked-step' : ''}`}  onClick={onClick} onKeyDown={handleKeyDown} {...other}>
            {(completed) ?
                <img className="radio-img" src="./icons/radio-done.svg" alt='הושלם' />
                :
                (current) ?
                    <img className="radio-img" src="./images/progress-bar.svg" alt='נוכחי' />
                    :
                    <img className="radio-img" src="./icons/radio.svg" alt='לא בוצע' />
            }
            {children}
        </div>)
}
