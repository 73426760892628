import { v4 as uuidv4 } from 'uuid';
import { useMemo, useState } from 'react';
import './planSelect.css'

export default function PlanSelect({ id, value, label, className = '', tooltip, required = false, style, setOpenPopup, disabled, errorText, OnValueButtonLabel, ...other }) {
    //local State Managment

    const [touched, setTouched] = useState(false)

    const validationMessage = (required && touched && !value) ? 'זהו שדה חובה.' : '';

    // Handle Dynamic element Id    
    id = useMemo(() => (id) ? id : uuidv4(), [id]);


    const handleOpenPopup = () => {
        if (!disabled && (typeof (setOpenPopup) === 'function'))
            setOpenPopup(true)
        setTouched(true)
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) handleOpenPopup();
    }


    return (
        <div className={`field-wrapper ${className}`} style={style}>
            <label htmlFor={id}>{label}
                {(tooltip) && <div role="tooltip" aria-label={label} tool-tip={tooltip}><img src="./icons/question-mark-circle.svg" alt='?' /></div>}
            </label>
            <div className='plan-select-wrapper' onClick={handleOpenPopup} disabled={disabled}>
                <div className={`input-wrapper ${(validationMessage) ? 'invalid' : ''}`}>
                    <input placeholder={"בחירת מסלול"} readOnly disabled={disabled} value={!!(value) ? value : ''}
                        className='input-select'
                        autoComplete="off"
                        onKeyDown={handleKeyDown}
                        id={id}
                    />
                </div>
                <div className={`plan-select-button ${(disabled) && 'disabled'} `} >
                    {(value) ? (OnValueButtonLabel) ? OnValueButtonLabel : 'שינוי' :
                        'בחירה'}
                </div>
            </div>
            {!!validationMessage && (
                <div className='validation-message'>
                    {errorText || validationMessage}
                </div>
            )}
        </div>
    )
}
