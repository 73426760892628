import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';

export default function Input({ type = "text", value, onChange, name, id, label, className = '', tooltip, required = false, readonly = false, style, errorText, myRef, maxlength, invalid = false, ...other }) {
    // Local State Managment
    const [validationMessage, setValidationMessage] = useState("")
    const [touched, setTouched] = useState(false)

    useEffect(() => {
        if (touched && invalid)
            if (!validationMessage)
                setValidationMessage("שדה חובה.")
    }, [touched, invalid, validationMessage])

    const onInvalid = (e) => {
        if (type === 'email' && e.target?.validity?.typeMismatch)
            setValidationMessage("תבנית מייל לא תקינה")

        else if (type === 'tel' && e.target?.validity?.patternMismatch)
            setValidationMessage("תבנית טלפון לא תקינה")
        else
            setValidationMessage("שדה חובה.")
    }

    const onBlur = (e) => {
        setTouched(true);
        if (required) e.target.checkValidity()
    }

    const handleChange = (e) => {
        if (type === 'number' && maxlength) {
            if (e.target.value.length > maxlength)
                return
        }
        if (touched && required)
            e.target.checkValidity()
        if (!!validationMessage && e.target.checkValidity())
            setValidationMessage('')
        if (typeof (onChange) === 'function')
            onChange(e.target.value);
    }


    // Handle Dynamic element Id    
    id = useMemo(() => (id) ? id : uuidv4(), [id]);

    return (
        <div className={`field-wrapper ${className}`} style={style}>
            <label htmlFor={id}>{(required) ? <>*&nbsp;{label}</> : <>{label}</>}
                {(tooltip) && <div role="tooltip" aria-label={name}  tool-tip={tooltip}><img src="./icons/question-mark-circle.svg" alt='?' /></div>}
            </label>
            <div className="input-wrapper">
                <input ref={myRef}
                    value={value}
                    onChange={handleChange}
                    autoComplete="off"
                    type={type} name={name} id={id}
                    required={required}
                    onInvalid={onInvalid}
                    onBlur={onBlur}
                    readOnly={readonly}
                    maxLength={maxlength}
                    lang={(type==='email')?'en':undefined}
                    {...other}
                />
            </div>
            {!!validationMessage && (
                <div className='validation-message'>
                    {errorText || validationMessage}
                </div>
            )}
        </div>
    )
}


