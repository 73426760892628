
import Input from '../input/Input';
import Select from '../select/Select';
import Button from '../buttons/Button';
import './registrationDonorDetails.css';
import RegistrationUnitsSummary from '../registrationUnitsSummary/RegistrationUnitsSummary';
import RegistrationTerms from '../registrationTerms/RegistrationTerms';
import { useContext } from 'react';
import { appContext } from '../../service/AppContext';
import { registrationContex } from '../../service/RegisrationContext';
import { formatNumber, getPersonIdValidation } from '../../service/inputUtil';
import { useState } from 'react';

export default function RegistrationDonorDetails({ onCompleted }) {

    const { isPending, setFetch, urlAgent } = useContext(appContext)
    const { completedKids, donor, setDonor, signed } = useContext(registrationContex)

    const [donorIdValidation, setDonorIdValidation] = useState({ invalid: false, errorText: '' })

    const handleSubmit = (e) => {
        e.preventDefault();

        const formElement = e.target;
        const isValid = formElement.checkValidity() && !donorIdValidation.invalid;

        formElement.classList.add('submitted');

        if (isValid) {
            if (!isPending) {
                setFetch({
                    route: '/unit-request',
                    options: {
                        method: 'POST',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            id: donor?.id,
                            urlAgent,
                            donor,
                            kids: completedKids?.map(kid => { return { ...kid, plan: kid.plan?.route, planName: kid.plan?.title } }),
                            joiningSignature: signed.joiningSignature,
                            interestSignature: signed.interestSignature
                        })
                    }
                })
                if (typeof (onCompleted) === 'function')
                    onCompleted(e);
            }
        }
        else {
            const firstInvalidField = formElement.querySelector(":invalid");
            if (firstInvalidField)
                firstInvalidField?.focus();
            else if (donorIdValidation?.invalid)
                formElement.querySelector('#person-id').focus()
        }

    }


    const HandleUpdateDonorCB = (key) => {
        if (key === 'phone') return updatePhoneNumber
        if (key === 'mobile') return updateMobilePhoneNumber
        if (key === 'personId') return updateId
        return updateDonorKey


        function updatePhoneNumber(value) {
            value = formatNumber(value, { allowDesh: true });
            updateDonorKey(value);
        }

        function updateMobilePhoneNumber(value) {
            value = formatNumber(value, { maxLength: 10, partLength: 3, repeat: false });
            updateDonorKey(value);
        }
        function updateId(value) {
            value = formatNumber(value);
            let personIdValidation = getPersonIdValidation(value)
            if (!personIdValidation.invalid && completedKids?.some(kid => kid.personId === value)) {
                personIdValidation = { invalid: true, errorText: 'ת.ז כבר בשימוש' }
            }
            setDonorIdValidation(personIdValidation)
            updateDonorKey(value);
        }

        function updateDonorKey(value) {
            setDonor(prev => { return { ...prev, [key]: value } })
        }

    }


    return (
        <div className="donor-details card-content-wrapper">
            <form onSubmit={handleSubmit} noValidate>
                <div className='details-wrapper'>
                    <div className='donor-info'>
                        <div role="heading" aria-level="2" className='heading-h3' >מלאו את הפרטי ההורה/התורם</div >
                        <div className="fields-flex">
                            <Input className='first-name' value={donor.firstName} onChange={HandleUpdateDonorCB('firstName')} type='text' name='first-name' id='first-name' label='שם פרטי' required />
                            <Input className='last-name' value={donor.lastName} onChange={HandleUpdateDonorCB('lastName')} type='text' name='last-name' id='last-name' label='שם משפחה' required />
                            <Input value={donor.personId} onChange={HandleUpdateDonorCB('personId')} type='text' name='person-id' id='person-id' label='תעודת זהות' required maxlength={9} invalid={donorIdValidation?.invalid} errorText={donorIdValidation?.errorText} />
                            <Input value={donor.email} onChange={HandleUpdateDonorCB('email')} type='email' name='mail' id='mail' label='כתובת דוא"ל' required pattern="\w+(.+\w+)?@\w+(.\w+)+" />
                            <Input value={donor.phone} onChange={HandleUpdateDonorCB('phone')} type='tel' name='phone' id='phone' label='טלפון בבית' pattern="[0-9]{2,3}-?[0-9]{7}" maxlength={11} />
                            <Input value={donor.mobile} onChange={HandleUpdateDonorCB('mobile')} type='tel' name='mobile' id='mobile' label='טלפון נייד' required pattern="[0-9]{3}-?[0-9]{7}" maxlength={11} />

                            <Select value={donor.sector} onChange={HandleUpdateDonorCB('sector')} name="sector" label='מגזר'  >
                                <option value="" disabled hidden>בחירה</option>
                                <option value="Hasidic">חסידי</option>
                                <option value="Litayi">ליטאי</option>
                                <option value="Sfaradi">ספרדי</option>
                            </Select>
                            {/* <div className="comma-notes">* שדות חובה</div> */}
                        </div>
                        <div className="is-member" role='radiogroup'>
                            <div role="heading" aria-level="3" className='heading-h4'>האם אתה חבר בגמ"ח</div >
                            <div className="radio-wrapper">
                                <label htmlFor="member"><input type="radio" name="is-member" id="member" checked={donor.isMember} onChange={(e) => HandleUpdateDonorCB('isMember')(true)} />אני חבר בגמ"ח המרכזי</label>
                                <label htmlFor="not-member"><input type="radio" name="is-member" id="not-member" checked={donor.isMember === false} onChange={(e) => HandleUpdateDonorCB('isMember')(false)} />אני עדיין לא חבר בגמ"ח המרכזי</label>
                            </div>
                        </div>
                        <hr className='card-hr hr-middle' />
                    </div>
                    <RegistrationUnitsSummary />
                    <RegistrationTerms />
                    <Button label='מעבר לתשלום' />
                </div>
            </form>
        </div>
    )
}

