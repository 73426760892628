


export default function PageHeader({ title, description, extra, className = "", wrapper = false, children }) {
    return (
        <section className={`title-section ${className}`}>
            {
                (wrapper) ?
                    <div className='title-wrapper'>
                        <h1>{title}</h1>
                        {children}
                        {(!!description) && <p> {description} {(!!extra) && <><br /> {extra}</>}</p>}
                    </div>
                    :
                    <>
                        <h1>{title}</h1>
                        {children}
                        {(!!description) && <p> {description}{(!!extra) && <><br /> {extra}</>}</p>}
                    </>
            }
        </section>
    )
}
