export const baseStyle = `outline: none
color: var(--neutrals-neutral-900, #000204);
text-align: right;
direction:rtl;
font-size: 16px;
font-style: normal;
font-weight: 400;
// font-family: SimplerPro;
// line-height: 20px;
line-height: 25px;
padding:8px;
border-radius:4px;
width:-webkit-fill-available;
border: 1px solid #d9ddec;
margin:2px;
font-family:
`

export const invalidStyle = `
border: 1px solid #ED0722 !important;
`

export const focusStyle = `
border: 1px solid #3b82f6;
    background: #fff;`
