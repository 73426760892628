import Button from "./Button"

export default function TabSelector({ tabs, current, mobile = false, tabIndex }) {
    const renderButtons = tabs.map((tab, index) =>
        <Button key={index} size={(mobile) ? 'small' : 'null'} className={`${(mobile) ? 'mobile-tab-button' : 'tab-button'}`} color={(index === current) ? 'white-tab-selector' : 'gray-tab-selector'} id={tab?.id} onClick={tab?.onClick} label={tab?.label} tabIndex={tabIndex} />
    )

    return (
        <div className={`tab-menu ${(mobile) ? 'mobile' : 'desktop'}`}>
            {renderButtons}
        </div>
    )
}
