import React, { useEffect } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import Disclaimer from '../../components/accessibilityDisclaimer/accessibiltyDisclaimer'

export default function AccessibilityPage() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = 'הצהרת נגישות'
        return () => {
            document.title = prevTitle
        }
    }, [])


    return (
        <>
            <PageHeader title='הצהרת נגישות' />
            <Disclaimer />
        </>
    )
}
