import { useState, createContext, useRef, useMemo, useContext } from 'react';
import './TimelineGantt.css'
import TimelineHeader from './TimelineHeader';
import TimelineBG from './TimelineBG';
import TimelineContent from './TimelineContent';
import { familyPlanReturnMap, simulatorContext } from '../../service/SimulatorContext';
import {  toJewishDate } from 'jewish-date';

export const ganttContext = createContext();

export default function TimelineGantt({ completedKids, jewishDate }) {

    const [ready, setReady] = useState(null);

    const [focusedUnit, setFocusedUnit] = useState();

    const bgRef = useRef()

    const { timeLine } = useContext(simulatorContext);
    const { units, dates } = timeLine;

    const grantPercent = familyPlanReturnMap[completedKids.length] * 100;

    const value = { focusedUnit, setFocusedUnit, grantPercent, bgRect: bgRef.current?.getBoundingClientRect()};

    let today = new Date();
    let currentYear = today.getFullYear();
    let maxDate = new Date(Math.max(...dates, (new Date()).setFullYear(currentYear + 20)));
    let maxYear = maxDate.getFullYear() + 2;
    let yearStart = currentYear - 2;
    let numOfYears = maxYear - yearStart;
    let years = useMemo(() =>
        Array.apply(null, Array(numOfYears))
            .map(function (x, i) {
                let year = new Date(yearStart + i, 0);
                return jewishDate ? toJewishDate(year).year : yearStart + i;
            }),
        [yearStart, numOfYears, jewishDate]);


    return (
        <div style={{ display: "flex", flexDirection: 'column', position: 'relative', width: '100%' }} >
            <ganttContext.Provider value={value}>
                <TimelineHeader years={years} jewishDate={jewishDate} />
                <TimelineContent units={units} ready={ready} years={years} jewishDate={jewishDate}/>
                <TimelineBG years={years} setReady={setReady} focusedUnit={focusedUnit} bgRef={bgRef} jewishDate={ jewishDate} />
            </ganttContext.Provider>
        </div>)

}
