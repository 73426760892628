import  { useContext, useState } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import ExportCsv from '../exportCsv/ExportCsv'
import ExportPdf from '../exportPdf/ExportPdf'

export default function SimulatorMobileMenu() {
    const [openMenu, setOpenMenu] = useState(false)
    const { handleResetKids } = useContext(simulatorContext)


    const handleToggleMenu = () => {
        setOpenMenu(prev => !prev)
    }

    return (
        <div className="cards-menu mobile" tabIndex={0} onBlur={(e) => { if (!e.relatedTarget?.download) setOpenMenu(false) }} >
            <img className="menu-icon-mobile" src="./icons/dots-menu.svg" alt='menu icon' onClick={handleToggleMenu} />
            <div className={`menu-items align-center ${(openMenu) ? 'show-menu' : ''}`}     >
                <div className="align-center" onClick={handleResetKids}><img src="./icons/trash.svg" alt='trash can' /><span style={{ color: "var(--neutrals-neutral-900, #000204)" }}>נקה טופס</span></div>
                <ExportCsv />
                <ExportPdf />
            </div>
        </div>
    )
}
