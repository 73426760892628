
export default function Header() {
    return (
        <header>
            <div className="header-container max-content">
                <div className="logo-container">
                    <a href="https://glb.org.il/" target="_blank"  rel="noreferrer" ><img className="logo-icon" alt='לוגו גמח' src="./images/logo.png" /></a>
                        
                </div>
            </div>
        </header>
    )
}
