import React, { useState } from 'react'
import './paymentMethod.css'
import CreditCardForm from '../creditCardForm/CreditCardForm'
import StandingOrderSelector from '../standingOrderSelector/StandingOrderSelector'

export default function PaymentMethod({ monthlyDonation, onCompleted }) {

    const [mainMethod, setMainMethod] = useState('creditCard')

    return (
        <div className='payment-method'>
            <div  role="heading" aria-level="2" className='heading-h3'>אמצעי תשלום</div>
            <div className='payment-value'>
                <p className='sub-header'>הסכום החודשי לחיוב</p>
                <div>
                    <h3>₪{monthlyDonation?.toLocaleString()}</h3>
                </div>
            </div>
            <label htmlFor="credit-card">
                <input type="radio" name="main-method" id="credit-card" checked={mainMethod === 'creditCard'} onChange={(e) => setMainMethod('creditCard')} />תשלום בכרטיס אשראי
            </label>
            {(mainMethod === 'creditCard') &&
                <CreditCardForm onCompleted={onCompleted} />
            }
            <hr className='card-hr hr-middle' />
            <label htmlFor="bank-standing-order">
                <input type="radio" name="main-method" id="bank-standing-order" checked={mainMethod === 'bankStandingOrder'} onChange={(e) => setMainMethod('bankStandingOrder')} />תשלום בהוראת קבע בנקאית            
            </label>
            {(mainMethod === 'bankStandingOrder') &&
                <StandingOrderSelector onCompleted={onCompleted} />
            }

        </div>
    )
}
