// import "./styles.css";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import ChartTooltip from "./ChartTooltip";

export default function GrantsChart({ completedKids, totalGrant, label }) {



    const kidToPoint = (kid) => {
        // Calculate grant year
        let year = new Date();
        let monthsWait = (kid.marriage - kid.age) * 12 - kid.plan?.monthsSave;
        monthsWait = (monthsWait > 0) ? monthsWait : 0;
        let delta = (kid.marriage - kid.age) * 12 + monthsWait
        let payments = Math.round((kid.plan?.loan - kid.plan?.return) / kid.plan?.monthlyReturn)
        let date = new Date(year.setMonth(year.getMonth() + delta + payments))
        // Grant value
        let value = kid.plan?.return * Number(kid.units)
        return { date, value }
    }

    const accumulatedKidsPoints = (completedKids) => {
        let sum = 0;
        let kidsPoints = completedKids.map(kid => kidToPoint(kid)).sort((a, b) => a.date - b.date);
        for (let i = 0; i < kidsPoints.length; i++) {
            kidsPoints[i].value += sum;
            sum = kidsPoints[i].value;
        }
        return kidsPoints
    }

    const pointsToChartData = (points) => {
        const res = [];
        const startYear = new Date().getFullYear() % 100;
        const lastYear = Math.max(points[points.length - 1]?.date?.getFullYear() % 100 + 1, startYear + 25);
        let isfirstPoint = true;
        for (let year = startYear; year <= lastYear; year++) {
            for (let month = 0; month < 12; month++) {
                let point = points?.find(point => (point.date.getFullYear() % 100) === year && point.date.getMonth() === month)
                if (!point) {
                    res.push({ ...res[res.length - 1], year, month });
                    continue;
                }
                if (isfirstPoint && res.length) {
                    res[res.length - 1].value = 0;
                    isfirstPoint = false;
                }
                res.push({ ...point, year, month })
            }
        }
        return res
    }

    const allkidsPoints = accumulatedKidsPoints(completedKids);
    const chartData = pointsToChartData(allkidsPoints);

    const tickStyle = {
        fontFamily: 'SimplerPro',
        fontSize: 10,
        color: 'var(--neutrals-neutral-700-text, #495157)',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%'
    }
    return (
        <div className="graph" dir="ltr">
            <div className="graph-header" dir="rtl">
                <div className="graph-value">
                    <span className="shekel-mrk">₪</span>{totalGrant?.toLocaleString('he-IL')}
                </div>
                <div className="graph-label">{label}</div>
            </div>
            <ResponsiveContainer height={184} width='100%'>
                <AreaChart data={chartData} margin={{ top: 24, right: 16, left: 16, bottom: 24 }}>
                    <defs>
                        <linearGradient id="gradiantColorFill" >
                            <stop stopColor="#EDD097" stopOpacity="0.8" />
                            <stop offset="1" stopColor="#EDD097" stopOpacity="0.5" />
                        </linearGradient>
                    </defs>
                    <CartesianGrid orizontal={true} vertical={false} />
                    <XAxis dataKey="year" tickFormatter={tick => "'" + tick} interval={59} tickLine={false} axisLine={false} tick={tickStyle} />
                    <YAxis type="number"
                        tickFormatter={data => data.toLocaleString('he-IL')}
                        tickLine={false}
                        axisLine={false}
                        tick={tickStyle}
                        tickCount={7}
                        domain={[0, dataMax => {
                            let digits = (dataMax.toString().length) - 1
                            return (Math.round(dataMax / (10 ** digits)) + 1) * (10 ** digits)
                        }]}
                    />
                    <Area type="basis" dataKey="value" stroke="#CCA559" stroke-linecap="round" fill="url(#gradiantColorFill)" strokeWidth={2} activeDot={<circle r="3" fill="#172554" stroke="#172554" />} />
                    <Tooltip cursor={{ stroke: ' var(--brand-blue-900-bg, #172554)', strokeDasharray: "3 3", strokeWidth: 1 }} content={<ChartTooltip />} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
