import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useRef } from 'react';

export default function InputRange({ name, id, label, className = '', style, min = 0, max = 8, step = 1, value = 0, onChange, summary = false, ...other }) {

  const rangeRef = useRef(null)

  // Handle Dynamic element Id    
  id = useMemo(() => (id) ? id : uuidv4(), [id]);

  const ticksAmount = (summary) ? 8 : max;

  let ticks = []
  for (let i = min; i <= ticksAmount; i = i + step) {
    ticks.push(<option value={i} key={i}></option>)
  }
  const handleChange = (event) => {
    // Update via Prop CallBack Function
    if (typeof (onChange) === 'function')
      onChange(event.target.value);
  }

  //Redraw track
  useEffect(() => {
    drawRangeTrack()
  }, [value])

  // Color range track
  function drawRangeTrack() {
    let target = rangeRef?.current
    const min = target.min
    const max = target.max
    const val = target.value
    let percentage = (max - min > 0) ? (val - min) * 100 / (max - min) : 0
    target.style.backgroundSize = percentage + '% 100%'
  }

  return (
    <div className={`range-wrapper ${className}`} style={style}>
      <input ref={rangeRef}
        aria-label='silder'
        className={(summary) ? "total-range" : ''} type="range"
        value={value}
        onChange={handleChange}
        min={min} max={max} step={step} name={name} id={id}
        {...other}
      />
      <datalist>
        {ticks}
      </datalist>
    </div>
  )
}
