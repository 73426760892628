import React, { useContext, useState } from 'react'
import './registrationTerms.css'
import Step from '../cardSteps/Step'
import { registrationContex } from '../../service/RegisrationContext'
import JoiningTermsPopup from './JoiningTermsPopup'
import InterestTermsPopup from './InterestTermsPopup'

export default function RegistrationTerms() {

    const [termsPopup,setTermsPopup] =useState({joining:false,interest:false})
    const {signed,setSigned} = useContext(registrationContex)

    const termPopupCB = (obj) => {
        return () => {
            setTermsPopup(prev=> {return {...prev ,...obj}} )
        }
    }

    const signTermCB = (key) => {
        return (value) => {
            setSigned(prev=>{return {...prev , [key]:value}})
        }
    }

    return (
        <div className='registation-terms'>
            <h4>אישור תקנוני הגמ״ח</h4>
            <p className='sub-header'>יש לאשר בחתימה דיגיטלית את שני התקנונים</p>
            <div className='terms-container' >
                <Step completed={signed?.joining} onClick={termPopupCB({joining:true})} tabIndex={0}>
                    <u className='step-label'>תקנון הצטרפות לגמ״ח</u>
                </Step>
                <Step completed={signed?.interest}  onClick={termPopupCB({interest:true})}  tabIndex={0}>
                    <u className='step-label' >תקנון ריביות</u>
                </Step>

            </div>

            {(termsPopup?.joining) && <JoiningTermsPopup closePopup={termPopupCB({joining:false})} onSign={signTermCB('joining')} signature={signed?.joiningSignature} setSignature={signTermCB('joiningSignature')}/>}
            {(termsPopup?.interest) && <InterestTermsPopup closePopup={termPopupCB({interest:false})} onSign={signTermCB('interest')} signature={signed?.interestSignature} setSignature={signTermCB('interestSignature')} />}
        </div>)
}
