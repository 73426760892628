import React from 'react'
import Popup from '../popup/Popup'
import SingleFrame from '../singleFrame/SingleFrame'


export default function ChildRowPopup({ kid, closePopup }) {
    return (
        <Popup  closePopup={closePopup} >
            <div className="title-wrapper">
                <h3>סיכום תרומה והלוואה</h3>
                <p className='sub-header'>לכל גיל מסלול שונה, אנא בחרו את המסלול המתאים לכם</p>
            </div>
            <div className='child-row-popup'>
                <SingleFrame label={'שם הילד/ה'} value={kid?.name} />
                <SingleFrame label={'תעודת זהות'} value={kid?.personId} />
                <SingleFrame label={'תאריך לידה'} value={(kid?.preBorn) ? 'טרום לידה' : kid?.birthday} />
                <SingleFrame label={'מסלול/תוכנית'} value={kid?.plan?.title} />
                <SingleFrame label={'כמות יחידות'} value={kid?.units} />
                <SingleFrame label={'תרומה חודשית'} value={kid?.plan?.monthlyPay * kid?.units} currency={true} />
                <SingleFrame label={'תשלומים'} value={kid?.plan?.totalPay / kid?.plan?.monthlyPay} />
                <SingleFrame label={'סה"כ תרומה'} value={kid?.plan?.totalPay * kid?.units} currency={true} />
                <SingleFrame label={'החזר חודשי'} value={kid?.plan?.monthlyReturn * kid?.units} currency={true} />
                <SingleFrame label={'תשלומים'} value={Math.ceil(kid?.plan?.loan / kid?.plan?.monthlyReturn)} />
                <SingleFrame label={'סה"כ הלוואה'} value={kid?.plan?.loan * kid?.units} currency={true} />
                <SingleFrame label={'מענק'} value={kid?.plan.return * kid?.units} currency={true} />

            </div>
        </Popup>
    )
}
