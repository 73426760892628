import { toHebrewJewishDate, } from 'jewish-date';

export default function TimelineHeader({ years, jewishDate }) {

    return (
        <div className="gantt-header">
            {years?.map((year, idx) =>
                <div key={idx} className={(jewishDate)?'jewish-header':''}>
                    <div >
                        {(!(idx % 2)) && (jewishDate ? toHebrewJewishDate({ year, month: 1, day: 1 }).year.slice(1) : year)}</div>
                </div >
            )
            }
        </div>)
}
