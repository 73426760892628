import React, { useContext } from 'react'
import './registrationUnitsSummary.css'
import { registrationContex } from '../../service/RegisrationContext'
import RegistrationKidsTable from '../registrationKidsTable/RegistrationKidsTable'
import SingleFrame from '../singleFrame/SingleFrame'
export default function RegistrationUnitsSummary() {

  const { summaryKids } = useContext(registrationContex)

  

  return (
    <div className='registation-units-summary'>
      <h3>סיכום תרומות והלוואות</h3>
      <div className='total-rows-wrapper'>
        <SingleFrame label='סה"כ תרומה חודשית' value={summaryKids?.totalMonthlyDonation.toLocaleString()} currency={true} />
        <SingleFrame label='סה"כ תרומות' value={summaryKids?.totalDonation.toLocaleString()} currency={true}/>
        <SingleFrame label='סה"כ החזר חודשי' value={summaryKids?.totalMonthlyReturn.toLocaleString()} currency={true}/>
        <SingleFrame label='סה"כ הלוואות' value={summaryKids?.totalLoan} currency={true}/>
        <SingleFrame label='סה"כ מענקים' value={summaryKids?.totalReturn.toLocaleString()} currency={true}/>
      </div>
      <div className="children-wrapper">
        <h4>רשימת ילדים לצירוף לגמ״ח</h4>
        <RegistrationKidsTable/>
      </div>

    </div>

  )
}
