import { monthName } from '../../service/SimulatorContext';


export default function ChartTooltip({ active, payload }) {
    if (active && payload && payload.length && payload[0].value!==undefined) {
        const year = '20' + payload[0].payload.year;
        const month = monthName[payload[0].payload.month];
        const value = payload[0].value.toLocaleString('he-IL');
        return (
            <div className="graph-data-buble">
                <div className='label'>{month}&nbsp;{year}</div>
                <div className='value'><span className="shekel-mrk">₪</span>{value}</div>
            </div>
        );
    }
    return null
}
