import React, { useEffect, useRef } from 'react'
import './popup.css'

export default function Popup({ className = '', closePopup, children }) {
    const popupRef = useRef()

    useEffect(() => {
        const handleFocusOut = (ev) => { if (!popupRef.current?.contains(ev.relatedTarget)) popupRef.current?.focus() }

        let refCopy;
        if (popupRef.current) {
            popupRef.current.focus();
            refCopy = popupRef.current
            refCopy.addEventListener('focusout', handleFocusOut)
        }

        return () => {
            if (refCopy)
                refCopy.removeEventListener('focusout', handleFocusOut);
        }

    }, [])

    const handleCloseKeyDown = (e) => {
        if (typeof (closePopup) !== 'function') return
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            closePopup(e)
        }
    }

    const handleEsc = (e) => {
        if (typeof (closePopup) !== 'function') return
        if (e.keyCode === 27) {
                
            closePopup(e)
        }
    }

    return (
        <div className={`cover ${className}`} >
            <div className='popup-wrapper' ref={popupRef} tabIndex={0} onKeyDown={handleEsc}>
                <div className='close-wrapper'>
                    <img className='close-popup' src="./icons/x-mark.svg" alt='סגירה' onClick={closePopup} width={24} tabIndex={0} onKeyDown={handleCloseKeyDown} />
                </div>
                {children}
            </div>

        </div>
    )
}
