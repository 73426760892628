import React, { useState } from 'react'
import './kidsTableChildRow.css'
import ChildRowPopup from './ChildRowPopup'

export default function KidsTableChildRow({ kid }) {

  const { name, personId, birthday, plan, preBorn, units } = kid;

  const [openPopup, setOpenPopup] = useState(false)

  const handleTogglePopup = () => {
    setOpenPopup(prev => !prev)
  }

  const handleKeyDown = (e) => {
    if ([13, 32].includes(e.keyCode)) {
      setOpenPopup(true)
    }
  }

  return (
    <>
      <tr className='registration-child-row'>
        <td className='name'>{name}</td>
        <td className='preson-id'>{personId}</td>
        <td className='birthday'>{(preBorn) ? 'טרום לידה' : birthday}</td>
        <td className='plan-title'>{plan.title}</td>
        <td className='units'>{units}</td>
        <td className='monthly-donation'>₪{(plan.monthlyPay * units).toLocaleString()}</td>
        <td className='payments'>{plan.totalPay / plan.monthlyPay}</td>
        <td className='total-donation'>₪{(plan.totalPay * units).toLocaleString()}</td>
        <td className='monthly-return'>₪{(plan.monthlyReturn * units).toLocaleString()}</td>
        <td className='return-payments'>{Math.ceil(plan.loan / plan.monthlyReturn).toLocaleString()}</td>
        <td className='total-loan'>₪{(plan.loan * units).toLocaleString()}</td>
        <td className='total-loan-msg'>סה"כ הלוואה</td>
        <td className='grant'>₪{(plan.return * units).toLocaleString()}</td>
        <td className="menu-icon-mobile" onClick={handleTogglePopup} tabIndex={0} onKeyDown={handleKeyDown}>
          <img src="./icons/dots-menu.svg" alt='menu icon' />
        </td>
      </tr>
      {(openPopup) && <tr className='child-row-popup-wrapper'><td><ChildRowPopup kid={kid} closePopup={() => setOpenPopup(false)} /></td></tr>}
      <tr className='card-hr'>
        <td colSpan={12}></td>
      </tr>
    </>
  )
}
