export function formatNumber(value, option) {
    // debugger;
    let options = { maxLength: '', partLength: Infinity, separator: '-', allowDesh: false, repeat: false, ...option }
    value = (options.allowDesh) ? value.replace(/[^\d-]/g, "") : value.replace(/[^\d]/g, "")
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    let rg = new RegExp(`\\d{${options.partLength},${options.maxLength}}`, 'g')
    var matches = v.match(rg);
    var match = matches && matches[0] || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += options.partLength) {
        parts.push(match.substring(i, i + options.partLength))
        if (!options.repeat) {
            i += options.partLength
            let rest = match.substring(i, len)
            if (rest)
                parts.push(rest)
            i = len
        }
    }
    if (parts.length)
        return parts.join(options.separator)
    else
        return value
}

export function getPersonIdValidation(value) {
    if (String(value).length < 9)
        return { invalid: true, errorText: 'ת.ז קצרה מדיי' };
    if (String(value).length > 9)
        return { invalid: true, errorText: 'ת.ז ארוכה מדיי' };
    if (!validateId(value))
        return { invalid: true, errorText: 'ת.ז לא חוקית' };
    return { invalid: false, errorText: '' }
}


function validateId(personId) {
    var accumulator = 0;
    if (personId.length !== 9)
        return false
    for (let i = 0; i < personId.length; i++) {
        let multiplier = (i % 2 === 0) ? 1 : 2;
        let sum = personId[i] * multiplier;
        if (Math.floor(sum / 10) > 0) {
            let sumString = sum.toString()
            sum = Number(sumString[0]) + Number(sumString[1])
        }
        accumulator += sum;
    }
    return (accumulator % 10 === 0)
}
function callAgeLableMaper(type) {

    return function ageLableMaper(planNumber) {
        if (planNumber === 0)
            return ({ value: planNumber, label: planNumber })
        if (planNumber === 0.5)

            return ((type === 'minimized') ? { value: planNumber, label: 'עד 0.5' } : { value: planNumber, label: 'עד חצי שנה' })
        return ({ value: planNumber, label: 'עד ' + planNumber })
    }
}

export const ageOptions = [0.5, 3].map(callAgeLableMaper())
export const minimizedAgeOptions = [0.5, 3, 4, 6, 8, 10, 12, 14, 16].map(callAgeLableMaper('minimized'))