import React, { useEffect } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import PrivacyPolicy from '../../components/privacyPolicy/PrivacyPolicy'

export default function PrivacyPolicyPage() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = 'מדיניות הפרטיות'
        return () => {
            document.title = prevTitle
        }
    }, [])


    return (
        <>
            <PageHeader title='מדיניות הפרטיות' />
            <PrivacyPolicy />
        </>
    )
}
