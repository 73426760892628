import { appContext } from '../../service/AppContext';
import { formatNumber, getPersonIdValidation } from '../../service/inputUtil';
import Input from '../input/Input'
import { useContext, useEffect, useState, } from 'react'


export default function LeadForm() {

    const { setFetch, user, setUser, navigate, isPending, urlAgent } = useContext(appContext)

    const [donorIdValidation, setDonorIdValidation] = useState({ invalid: false, errorText: '' })

    const handleSubmit = (e) => {
        e.preventDefault();

        const formElement = e.target;
        const isValid = formElement.checkValidity() && !donorIdValidation.invalid;;
        formElement.classList.add('submitted');

        if (isValid) {
            const dataObject = new FormData(formElement);
            if (Object.values(urlAgent).some(v => v))
                dataObject.set('urlAgent', JSON.stringify(urlAgent))
            setFetch({ route: '/lead', options: { method: 'POST', body: dataObject } })
            // navigate('/simulator', { state: { user } })
        }
        else {
            const firstInvalidField = formElement.querySelector(":invalid");
            if (firstInvalidField)
                firstInvalidField?.focus();
            else if (donorIdValidation?.invalid)
                formElement.querySelector('#person-id').focus()
        }
    }

    useEffect(() => {
        if (user?.id)
            navigate('/simulator', { state: { user } })
    }, [user, navigate])

    const HandleUpdateUserCB = (key) => {
        if (key === 'phone') return updateUserPhone
        if (key === 'personId') return updateUserId
        return updateUserKey
        function updateUserKey(value) {
            setUser(prev => { return { ...prev, [key]: value } })
        }
        function updateUserPhone(value) {
            value = formatNumber(value, { allowDesh: true });
            updateUserKey(value);
        }
        function updateUserId(value) {
            value = formatNumber(value);
            if (value.length === 0)
                setDonorIdValidation({ invalid: false, errorText: '' })
            else setDonorIdValidation(getPersonIdValidation(value))
            updateUserKey(value);
        }
    }

    return (
        <div className="card-content-wrapper">
            <h3 >מלאו את הפרטים האישיים שלכם</h3>
            <form className={"client-info"} onSubmit={handleSubmit} noValidate>
                <div className="fields-grid-wrapper">
                    <div className="fields-grid2">
                        <Input value={user.lastName} onChange={HandleUpdateUserCB('lastName')} type='text' name='last-name' id='last-name' label='שם משפחה' required />
                        <Input value={user.firstName} onChange={HandleUpdateUserCB('firstName')} type='text' name='first-name' id='first-name' label='שם פרטי' required />
                    </div>
                    <Input value={user.phone} onChange={HandleUpdateUserCB('phone')} type='tel' name='phone' id='phone' label='טלפון' required pattern="[0-9]{2,3}-?[0-9]{7}" maxlength={11} />
                    <Input value={user.email} onChange={HandleUpdateUserCB('email')} type='email' name='mail' id='mail' label='כתובת דוא"ל' required pattern="\w+(.+\w+)?@\w+(.\w+)+" />
                    <Input value={user.personId} onChange={HandleUpdateUserCB('personId')} type='text' name='person-id' id='person-id' label='מספר תעודת זהות (אופציונלי)' maxlength={9} invalid={donorIdValidation?.invalid} errorText={donorIdValidation?.errorText} />
                </div>
                <div className="comma-notes">* שדות חובה</div>
                <button className="button" disabled={isPending}>המשך</button>
            </form>
        </div>)
}

