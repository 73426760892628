import { useContext } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import { appContext } from '../../service/AppContext'
import { exportToCSV } from '../../service/csvUtils'
// import { familyPlanReturnMap } from '../../service/SimulatorContext'

// import dotsMenu from './icons/dots-menu.svg'

export default function ExportCsv() {
    const { completedKids, summaryKids } = useContext(simulatorContext)
    const { user } = useContext(appContext)

    const HandleCsv = () => {
        const parseData = completedKids.map(kid => {
            let monthsWait = (kid.marriage - kid.age) * 12 - kid.plan?.monthsSave;

            return [
                kid.name,
                kid.age,
                kid.plan.title,
                kid.marriage,
                kid.plan.monthsSave,
                // Math.round(kid.plan.monthsSave / 12),
                (kid.plan?.totalPay * kid.units).toLocaleString('he-IL') + '₪',
                (kid.plan?.return * kid.units).toLocaleString('he-IL') + '₪',
                (monthsWait > 0) ? monthsWait : 0,
                (kid.plan?.loan * kid.units).toLocaleString('he-IL') + '₪',
            ]
        })
        const csvData = [

            {
                cols: [{ wch: 2 }, { wch: 16 }],
                name: 'סימולטור הלוואות',
                data: [
                    ['', {
                        v: "תוצאות סימולטור תרומות והלוואות",
                        s: { font: { sz: 16, bold: true, underline: true } },
                    }],
                    [],
                    (user.firstName || user.lastName)? ['לכבוד:', `${user.firstName} ${user.lastName}`]:[],
                    [],
                    ['משלמים את התרומות ומחזירים את ההלוואות בטווח זמן קצר יותר, ומבקבלים מענקים בהדרגה.'],

                ],
                options: {
                    origin: 'B0',
                    skipHeader: true,
                }
            },
            {
                name: 'סימולטור הלוואות',
                data: [
                    [{ v: "סיכום התוכניות שלכם", s: { font: { bold: true, underline: true } } }],
                    [{ v: 'תרומות', s: { font: { bold: true } } }, '', '', { v: 'הלוואות', s: { font: { bold: true } } }, '', ''],
                    ['סה"כ ילדים:', completedKids.length, '', 'סה"כ הלוואות:', summaryKids.totalLoan.toLocaleString('he-IL') + '₪'],
                    ['סה"כ תרומה חודשית:', summaryKids.totalMonthlyDonation.toLocaleString('he-IL') + '₪', '', 'סה"כ מענקים:', summaryKids.totalReturn.toLocaleString('he-IL') + '₪'],
                    ['סה"כ תרומה:', summaryKids.totalDonation.toLocaleString('he-IL') + '₪'],


                ],
                options: {
                    origin: 'B8',
                    skipHeader: true,
                }

            },

            {
                name: 'סימולטור הלוואות',
                data: [[{ v: 'פירוט תוצאות ופרטי ילדים', s: { font: { bold: true, underline: true } } }],
                [
                    'שם הילד\\ה',
                    'גיל',
                    'מסלול',
                    'גיל נישואים',
                    'חודשי תרומה',
                    // 'שנות תרומה',
                    'סה"כ תרומות',
                    'גובה המענק',
                    'חודשי המתנה להלוואה',
                    'סה"כ הלוואה לילד'
                ],
                ...parseData,
                ],
                // {v:'שם הילד\\ה',s:{border:{bottom:{color:'black'}}}},
                // cols: [{ wch: 10 }, { wch: 5 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 11 }, { wch: 17 }, { wch: 14 }],                        
                options: {
                    origin: 'B15',
                    skipHeader: true,
                }

            },
            {
                name: 'סימולטור הלוואות',
                data: [['כך תחתן את הילדים שלך בכבוד'],[],['*ידוע לי כי אין באמור כאן משום התחייבות כלשהי וכי הסכומים המאושרים הם אלו שיופיעו בהסכם ההצטרפות הרשמי מטעם הגמ"ח.']],
                options: {
                    origin: 'B' + (19 + completedKids?.length),
                    skipHeader: true,
                }
            }

        ]
        exportToCSV(csvData, 'סימולטור_תרומות_והלוואות');
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            HandleCsv();
        }
    }

    return (

        <div className="align-center" onClick={HandleCsv} tabIndex={0} onKeyDown={handleKeyDown} ><img src="./icons/excel.svg" alt='excel' />יצוא לאקסל</div>

    )
}
