import React from 'react'
import Card from '../card/Card'
import './terms.css'
import Button from '../buttons/Button'
import { useNavigate } from 'react-router-dom'

export default function Terms() {

    const navigate = useNavigate();

    const handleBack = (e) => {
        navigate(-1);
    }

    return (
        <Card className='terms'>
            <h2>טופס הסכמה לשימוש באזור האישי</h2>
            <p>אנא קראו את תנאי השימוש וסמנו בתיבת הסימון את הסכמתכם לתנאי השימוש.</p>
            <p>סימון אישור טופס הסכמה וביצוע פעולות משמעותה הסכמה לכל התנאים שלהלן.</p>
            <p>הגמ"ח המרכזי שע"י עמותת גמ"ח לבית בישראל ע.ר. 580403533 (להלן: "<strong>הגמ"ח המרכזי</strong>") מעמיד לרשותכם אפשרות לביצוע פעולות באזור האישי באתר האינטרנט</p>
            <p>ו/או באמצעות פלטפורמת 'נדרים פלוס' ו/או 'קהילות' (כל אלו להלן: "<strong>האזור האישי</strong>"), בכפוף לתנאים הבאים:</p>
            <br/>
            <ol>
                <li>המונח "משתמש" להלן משמעו כל אדם אשר עושה שימוש באתר האינטרנט ו/או באמצעות פלטפורמת 'נדרים פלוס' ו/או 'קהילות'.</li>
                <li>המשתמש מצהיר ומאשר כי קרא בעיון את תנאי השימוש באזור האישי ואת מדיניות הפרטיות של הגמ"ח המרכזי וכי הוא מסכים לכל התנאים המפורטים בהם.</li>
                <li>המשתמש מצהיר ומאשר שהוא קרא והבין את תקנון הגמ"ח המרכזי והשימוש באזור האישי הינה הסכמה והתחייבות לפעולות אך ורק על פי הוראות תקנון הגמ"ח המרכזי ולצורך פעילות חוקית בלבד.</li>
                <li>האזור האישי פותח כך שיתאים למערכות הפעלה ומחשבים מהסוגים הנפוצים בישראל. במקרה של קושי בשימוש באזור האישי נשמח לקבל משוב באמצעות הדוא"ל בכתובת: <a href='mailto:feedback@glb.org.il'>feedback@glb.org.il</a> יובהר כי אין התחייבות מצד הגמ"ח להתאמת השימוש באזור האישי לשימוש בכל מחשב שהוא.</li>
                <li>המשתמש מודע לכך שכל פעולה באמצעות האינטרנט מהווה סיכון לחשיפה לפעילות של גורמים מזיקים. על המשתמש מוטלת האחריות הבלעדית להגן על המחשב מפני שימוש לרעה. הגמ"ח המרכזי נוקט באמצעים סבירים על מנת להגן על מערכות המידע בהתאם לנהלי אבטחת מידע וניהול מאגרי נתונים אולם אין כל אחריות כלפי המשתמש מפני שימוש לרעה, או כל נזק שיכול להיגרם כתוצאה מהשימוש ברשת האינטרנט. מובהר ומותנה כי עצם השימוש באזור האישי פוטר את הגמ"ח מכל אחריות לנזקים שנגרמו כתוצאה מבעיות אבטחה במחשב של המשתמש.</li>
                <li>המשתמש מצהיר ומתחייב כי הוא אינו מתחזה ואינו מבצע פעולות בשם אדם אחר וכל הפרטים שימסור, כולל פרטים אישיים ופיננסיים, יהיו נכונים ומעודכנים.</li>
                <li>זכויות היוצרים באתר האינטרנט ובאזור האישי, לפי ההלכה ולהבדיל לפי דיני זכות יוצרים התקפים בישראל, שייכות לגמ"ח. רשות השימוש באזור האישי ובאתר האינטרנט הינם בהתאם להוראות תקנון הגמ"ח והסכם זה בלבד.</li>
                <li>המשתמש מבין ומביע בזאת את הסכמתו לכך שהגמ"ח המרכזי יאסוף ויעבד את המידע האישי והפיננסי של המשתמש לפי מדיניות הפרטיות המפורטת. המשתמש מסכים שהגמ"ח המרכזי ו/או מי מטעמו ישתמש במידע זה לצרכים שצוינו במדיניות הפרטיות, כולל התקשרות בנושאי פרסום והצעות.</li>
                <li>יובהר כי המשתמש יכול לסיים את השימוש באזור האישי בכל רגע נתון, אולם אין בכך בכדי לפטור אותו מהתחייבויותיו כלפי הגמ"ח. המשתמש מצהיר כי הוא מודע לזכויותיו בנוגע לגישה, עדכון ומחיקת המידע האישי כמתואר במדיניות הפרטיות.</li>
                <li>המשתמש מסכים לפטור מאחריות ולשפות את הגמ"ח, הצדדים הקשורים אליו ואת נושאי המשרה של הגמ"ח, שליחיהם ועובדיהם בהתאמה מפני ונגד כל תביעה של צד שלישי אשר תנבע או תהיה קשורה בכל דרך לשימושו באזור האישי תוך הפרה של הסכם זה.</li>
                <li>טופס ההסכמה מנוסחת בלשון זכר מטעמי נוחות בלבד אולם כל האמור בלשון זכר משמעו גם בלשון נקבה.</li>
            </ol>
            <h2>השימוש באזור האישי מותנה באישור הסכם זה והצהרת המשתמש כי הוא מסכים לכל התנאים המפורטים לעיל.</h2>
            <br />
            <Button label="חזרה" onClick={handleBack} />
        </Card>
    )
}
