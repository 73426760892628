import { useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useState, useRef } from 'react';

export default function Select({ name, id, value = "", onChange, label, className = '', size = 4, options, children, tooltip, placeholder = "בחר", required = false, style, locked = false, ...other }) {
    //local State Managment
    const [droppedDown, SetDroppedDown] = useState(false) //DropDown state
    const [selectLabel, setSelectLabel] = useState("") //Selected element Label
    const [dropSize, setDropSize] = useState(size)


    useEffect(() => {
        if (children?.length - 1 < size)
            setDropSize(children.length - 1)
        else if (options?.length < size)
            setDropSize(options.length)
        else
            setDropSize(size)
    }, [children, options, size])

    // Select HTML Ref Control
    const selectRef = useRef(null);
    const inputRef = useRef(null);

    // Handle update value via value prop
    useEffect(() => {
        if (value !== undefined && value !== '' && value !== null) {
            if (options?.find(item => item?.value === String(value))?.label)
                setSelectLabel(options?.find(item => item?.value === String(value))?.label);
            else if ([...selectRef?.current?.children]?.find(item => item?.value === String(value))?.label)
                setSelectLabel([...selectRef?.current?.children]?.find(item => item?.value === String(value))?.label);
            else setSelectLabel(value)
        }
        else
            setSelectLabel("");
        // selectRef.current.value = value;
    }, [value,options])

    // Handle Dynamic element Id    
    id = useMemo(() => (id) ? id : uuidv4(), [id]);

    // Handle Update Value 
    const handleChangeValue = (event) => {
        // Update via Prop CallBack Function
        if (typeof (onChange) === 'function')
            onChange(event.target.value);
        // Update ONLY visible label
        else
            setSelectLabel(options?.find(item => item.value === event.target.value).label);
        // Close DropDown
        // SetDroppedDown(false);
    }

    // Close DropDown on Blur
    const handleBlur = (event) => {
        if (event.relatedTarget !== inputRef.current)
            SetDroppedDown(false);
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            SetDroppedDown(prev => !prev)
        }
        else
            if (e.keyCode === 27)
                SetDroppedDown(false);
    }

    return (
        <div className={`field-wrapper ${className}`} style={style}>
            <label htmlFor={id}>{(required) ? <>*&nbsp;{label}</> : <>{label}</>}
                {(tooltip) && <div role="tooltip" aria-label={name} tool-tip={tooltip}><img src="./icons/question-mark-circle.svg" alt='?' /></div>}
            </label>
            <div className={`input-wrapper ${(droppedDown) ? 'dropped-down' : ''}`}>
                <input tabIndex={-1} ref={inputRef} value={selectLabel} placeholder={placeholder} readOnly required={required} disabled={locked}
                    className='input-select'
                    autoComplete="off"
                    name='select-input-label'
                    onClick={(locked) ? undefined : (e) => { selectRef.current.focus(); SetDroppedDown(prev => !prev) }}
                />
                <select id={id} name={name} size={dropSize} ref={selectRef} {...other} required={required}
                    onClick={(e) => { SetDroppedDown(false) }}
                    onChange={handleChangeValue}
                    onKeyDown={handleKeyDown}
                    tabIndex={(locked) ? -1 : 0}
                    onBlur={handleBlur}


                >
                    {(options) && options.map((item, index) => {
                        if (typeof (item) === 'number' || typeof (item) === 'string')
                            return <option key={index} value={item} >{item}</option>
                        else if (typeof (item) === "object" && item.hasOwnProperty('value') && item.hasOwnProperty('label'))
                            return <option key={index} value={item?.value} >{item?.label}</option>
                        return <></>
                    })}
                    {children}
                </select>
            </div>
        </div>
    )
}
