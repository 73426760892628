import React, { useContext } from 'react'
import Select from '../select/Select'
import './planSelectPopup.css'
import PlansCard from './PlansCard';
import { appContext } from '../../service/AppContext';
import Popup from '../popup/Popup';
import { ageOptions } from '../../service/inputUtil';

export default function PlanSelectPopup({ closePopup, setPlan, age, setAge, name, disabledAgeSelect = false }) {

    const { plans } = useContext(appContext);


    const filteredPlans = plans?.filter(plan => (age) ? (plan.age >= age && !plan?.familyPlan) : !plan?.familyPlan)

    const handleSetPlan = (plan) => {
        setPlan(plan?.route);
        closePopup();
    }

    return (
        <div  >
            <Popup closePopup={closePopup} >
                <div className='select-popup' >
                    <div className="title-wrapper">
                        <div role="heading" aria-level="2" className='heading-h3'>מסלולים רלוונטים {!!(name) && 'עבור ' + name} לפי גיל</div>
                    </div>
                    {(age <= 0.5) && < Select className="child-age" name="child-age" label="גיל" value={ageOptions?.find(option => option.value >= age)?.value} options={ageOptions} onChange={setAge} locked={disabledAgeSelect} />}
                    {!!(age !== null) && <PlansCard plans={filteredPlans} handleSetPlan={handleSetPlan} age={age} />}
                </div>
            </Popup>
        </div>

    )
}
