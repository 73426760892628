import { v4 as uuidv4 } from 'uuid';
import { useMemo, useState } from 'react';

export default function Checkbox({ value = "", onChange, name, id, label, className = '', tooltip, required = false, readonly = false, style, errorText, myRef, ...other }) {


    const handleChange = (e) => {
        if (typeof (onChange) === 'function')
            onChange((e.target.checked));
    }


    // Handle Dynamic element Id
    id = useMemo(() => (id) ? id : uuidv4(), [id]);

    return (
        <div className="checkbox-wrapper">
            <input ref={myRef} value={value} onChange={handleChange} autoComplete="off" type="checkbox" name={name} id={id} required={required} readOnly={readonly}  {...other} />
            <label htmlFor={id}>{(required) ? <>*&nbsp;{label}</> : <>{label}</>}
                {(tooltip) && <div role="tooltip" aria-label={name} tool-tip={tooltip}><img src="./icons/question-mark-circle.svg" alt='?' /></div>}
            </label>
        </div>
    )
}


