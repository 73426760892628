import { useContext,useEffect } from 'react'
import { appContext } from '../../service/AppContext'
import { toast } from 'react-toastify';

export default function Thanks() {
    
    const { error,navigate } = useContext(appContext);
    useEffect(() => {
        console.log(error)
        if (error) {
            toast.error("שמירת פרטים נכשלה", { position: toast.POSITION.BOTTOM_LEFT ,rtl:true});
            navigate('/simulator');
        }
        
    }, [error,navigate])
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>תודה רבה!</h1>
            <p style={{ marginTop: '30px' }}>פנייתך התקבלה במערכת, והועברה להמשך טיפול.</p>
            <p style={{ marginTop: '20px' }}>הגמ״ח המרכזי
                <br />
                <img className="logo"  src="./images/logo.png" alt='לןגן' />
            </p>
        </div>
    )
}
