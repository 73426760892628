import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import WizardPage from './pages/wizard/WizardPage'
import SimulatorPage from './pages/simulator/SimulatorPage';
import AppContext from './service/AppContext'
import Thanks from "./pages/thanks/Thanks";
import { ToastContainer } from 'react-toastify';
import RegistrationPage from './pages/registration/RegistrationPage'
import "react-toastify/dist/ReactToastify.css";
import AccessibilityPage from "./pages/accessibility/AccessibilityPage";
import TermsPage from "./pages/terms/TermsPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";

function App() {

  return (
    <BrowserRouter>
      <AppContext>
        <ToastContainer />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<WizardPage />} />
            <Route path="/simulator" element={<SimulatorPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
            <Route path="/accessibility" element={<AccessibilityPage />} />
          </Route>
        </Routes>
      </AppContext>
    </BrowserRouter >
  );
}

export default App;

