import Input from '../input/Input'
import Select from './Select'
import InputRange from '../input/InputRange'

export default function SelectUnits({value,onChange}) {
    return (
        <>
            <div className="select-units desktop-flex-end">
                <Input type="number" className="child-units" name="child-units" label="שווי ביחידות"
                    tooltip="כל יחידה מזכה בהלוואה בסכום ₪40,000. ניתן לצרף עד 8 יחידות לכל ילד/ה." min="0" max="8"
                    value={value}
                    disabled={(Number(value) === 0)}
                    readOnly
                    tabIndex={-1}
                />
                <Select className="child-value" name="child-value" label='סכום ההלוואה בש"ח' value={value} onChange={onChange} readOnly>
                    <option value="0" disabled hidden>בחר סכום</option>
                    <option value="1">40,000</option>
                    <option value="2">80,000</option>
                    <option value="3">120,000</option>
                    <option value="4">160,000</option>
                    <option value="5">200,000</option>
                    <option value="6">240,000</option>
                    <option value="7">280,000</option>
                    <option value="8">320,000</option>
                </Select>
            </div>
            <div className="fields-flex">
                <InputRange name="child-range-units" id="child-range-units" value={value} onChange={onChange}/>
            </div>
        </>
    )
}
