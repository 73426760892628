import { useContext } from "react";
import { ganttContext } from "./TimelineGantt";
import TimelineInfo from "./TimelineInfo";
// import TimelineFamilyInfo from "./TimelineFamilyInfo";
import { appContext } from "../../service/AppContext";

export default function TimelineItem({ name, loan, value, label, left, right, top, kid }) {

    const { focusedUnit, setFocusedUnit } = useContext(ganttContext);

    let isBlured = (focusedUnit && focusedUnit !== kid.id) ? true : false;

    let className = "gantt-item ";
    if (loan || focusedUnit === kid.id) className += 'loan ';
    if (isBlured) className += 'blured  ';

    const handleClick = (e) => {
        setFocusedUnit(kid?.id);
    }

    const handleKeyDown = (e) => {
        if (typeof (setFocusedUnit) !== 'function') return
        if ([13, 32].includes(e.keyCode)) {
            setFocusedUnit(kid?.id);
        }
        else
            if (e.keyCode === 27)
                setFocusedUnit();
    }

    return (
        <div style={{ left: left + "%", right: right + "%", top }} className={className}>
            <div className="gantt-item-wrapper" onClick={handleClick} tabIndex={0} onKeyDown={handleKeyDown}>
                <div className='description'>
                    {
                        (loan && kid.familyGrant) ? <span className='recive-grant-tag' >מימוש מענק משפחתי</span> :
                            <><span className="shekel-mrk">₪</span>{value}&nbsp;{label}</>
                    }
                </div>
                {(loan) && <div className='name'>{name}</div>}
            </div>
            {(loan && focusedUnit && focusedUnit === kid.id) ? <TimelineInfo kid={kid} top={top} /> : <></>}
        </div>
    )
}