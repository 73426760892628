import { useEffect, useRef, useState, memo } from 'react'
import Input from '../input/Input'
import Checkbox from '../input/Checkbox'
import Select from '../select/Select'
import PlanSelect from '../select/PlanSelect'
import PlanSelectPopup from '../planSelectPopup/PlanSelectPopup'
import './registerChildCard.css'
import DatePicker from '../datePicker/DatePicker'
import { dateToAge } from '../../service/dateUtil'


const RegisterChildCard = memo(({ index = 0, id, name, personId, birthday, age, preBorn, plan, units, updateCB, removeKid, personIdValidation, removeDisabled = false }) => {

  const showPlanSelect = (!!name && !!personId && age !== null);

  const PlanWithPrefix = (plan && age >= 1 && age <= 3) ? 'מרכזית - ' + plan?.title : plan?.title;

  const [minimize, setMinimze] = useState(false)
  const [openPopup, setOpenPopup] = useState(false) //Popup state

  const cardRef = useRef(true)
  const inputRef = useRef()

  const handleMinimize = (e) => {
    setMinimze(prev => !prev)
  }


  useEffect(() => {
    cardRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })?.then(() => {

    });
    setTimeout(() => {
      inputRef.current?.focus();
    }, 400);
  }, [])

  const handleUpdateBirthday = (e) => {
    if (typeof (updateCB) === 'function')
      updateCB(id, 'birthday')(e);
    if (dateToAge(e) <= 3)
      setOpenPopup(true)
    else if (openPopup && (!dateToAge(e) || dateToAge(e) > 16))
      setOpenPopup(false)

  }


  const handleUpdatePreBorn = (value) => {
    if (value)
      setOpenPopup(true)
    if (typeof (updateCB) === 'function')
      updateCB(id, 'preBorn')(value)
  }

  return (
    <div ref={cardRef} className="register-child-card" >
      <div className="title-wrapper">
        <div>
          <span className="child-index">{index + 1}.</span>
          {(name) ? <span className="child-title">{name}</span> : <span className="child-title gray-title">שם הילד</span>}
        </div>
        <img className={`card-minimize ${(minimize) && 'minimized'}`} src="./icons/chevron-up.svg" alt='הקטנת כרטיס' onClick={handleMinimize} />
      </div>
      <hr className="card-hr-desktop" />

      <div className="card-content-wrapper">

        <div className="fields-flex" >
          <Input myRef={inputRef} label="שם הילד/ה" className="child-name" name="child-name" placeholder="שם פרטי של הילד/ה" value={name} onChange={updateCB(id, 'name')} />

          <Input type='text' label="תעודת זהות" className="child-person-id" name="child-person-id" value={personId} onChange={updateCB(id, 'personId')} maxlength="9" invalid={personIdValidation?.invalid} errorText={personIdValidation?.errorText} />
          <div className='birthday-wrapper'>
            <DatePicker type={(preBorn) ? 'text' : 'date'} label="תאריך לידה" className="child-birthday" name="child-birthday" value={(preBorn) ? 'הילוד עדיין לא נולד' : birthday} onChange={handleUpdateBirthday} disabled={preBorn} max={new Date().toJSON().slice(0, 10)} >
              <Checkbox type='checkbox' label="טרום לידה -הילוד עדיין לא נולד" className="child-preBorn" name="child-preBorn" checked={preBorn} onChange={handleUpdatePreBorn} />
            </DatePicker>
          </div>
        </div>
      </div>
      <hr className={`card-hr-mobile`} style={(!!plan) ? {} : { display: 'none' }} />

      {(openPopup) && <PlanSelectPopup closePopup={() => setOpenPopup(false)} setPlan={updateCB(id, 'plan')} name={name} age={age} setAge={updateCB(id, 'age')}/>}
      <div className={`fields-flex card-buttom ${(showPlanSelect) ? '' : 'desktop-or-wide'}`}  >
        <PlanSelect className={`child-plan ${(showPlanSelect) ? '' : 'desktop-or-wide'}`} label="מסלול" tooltip="לכל גיל מסלול שונה. אנא בחרו את המסלול המתאים לכם." value={PlanWithPrefix} setOpenPopup={setOpenPopup} disabled={(age==null ||  dateToAge(birthday) >16)} required={true}
          errorText={(!age) ? "יש לבחור תאריך לידה" :
            (age < 0 || age > 16) ? "אין מסלולים לגיל זה" :
              "יש לבחור מסלול"}
              OnValueButtonLabel={(age > 3) && 'פרטים'}
        />

        <Select className={`child-units ${(!!plan) ? '' : 'desktop-or-wide'}`} name="child-units" label='יחידות תרומה' value={units} onChange={updateCB(id, 'units')} >
          <option value="0" disabled hidden>בחר כמות יחידות</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </Select>
        <Select className={`child-value ${(!!plan) ? '' : 'desktop-or-wide'}`} name="child-value" label='סך כל ההלוואה' value={units} onChange={updateCB(id, 'units')} >
          <option value="0" disabled hidden>בחר סכום</option>
          <option value="1">40,000</option>
          <option value="2">80,000</option>
          <option value="3">120,000</option>
          <option value="4">160,000</option>
          <option value="5">200,000</option>
          <option value="6">240,000</option>
          <option value="7">280,000</option>
          <option value="8">320,000</option>
        </Select>
      </div>
      <button className="remove-item align-center" onClick={(e) => { removeKid(id) }} disabled={removeDisabled}><img src="./icons/x-mark.svg" alt='הסר' />הסרה מרשימה</button>
    </div>
  )
})
export default RegisterChildCard