

import { monthName } from "../../service/SimulatorContext";

export default function SummaryWaitLoan({ startDate, endDate}) {
  
    let startText = monthName[startDate?.getMonth()] + '\xa0' + startDate?.getFullYear();
    let endText = monthName[endDate?.getMonth()] + '\xa0' + endDate?.getFullYear();


    return (
            <div className="summary-wait-loan" >
                <div className="summary-item-header">
                    <span>{startText}&nbsp;-&nbsp;{endText}</span>
                    <div className="item-value-wrapper">                  
                        <span className="label">זמן המתנה מסיום תרומה עד לקבלת הלוואה</span>
                    </div>
                </div>                
            </div>            
    )
}
