import React, { useEffect } from 'react'
import TimelineTick from './TimelineTick';
import { getJewishMonthsInOrder } from 'jewish-date';
export default function TimelineBG({ years, setReady, bgRef, jewishDate }) {

    useEffect(() => {

        if (setReady && bgRef.current?.getBoundingClientRect().width)
            setReady(years);
        else
            setReady(null);

        return () => { setReady(null) }
    }, [years, setReady,bgRef])

    function yearToTick(year) {
        if (jewishDate) {
            let months = getJewishMonthsInOrder(year).slice(1);
            return months.map((month) =>
                <TimelineTick key={month + '_' + year} year={year} month={month} jewishDate={jewishDate} />
            );
        }
        else {
            let months = Array.apply(null, Array(12)).map(function (x, i) { return i + 1; });
            return months.map((month) =>
                <TimelineTick key={month + '_' + year} year={year} month={month} jewishDate={ jewishDate} />
            );
        }
    }
    return (
        <div ref={bgRef} className="gantt-bg">
            {years.map(yearToTick)}
        </div>
    )
}
