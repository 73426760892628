import { Fragment } from 'react'
import './cardSteps.css'
import Step from './Step'



export default function CardSteps({ steps, currentStep }) {

    const stepsWithHr = steps.map((step, index) => {
        return (
            <Fragment key={index}>
                <Step current={(index === currentStep)} completed={(index < currentStep)} >
                    {step}
                </Step>
                {(index < steps.length - 1) ? <hr className="step-hr" /> : <></>}
            </Fragment>
        )
    })

    return (
        <div className="card-steps">
            <div className="steps-container">
                {stepsWithHr}
            </div>
        </div >
    )
}
