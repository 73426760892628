import { useContext } from 'react'
import TabSelector from '../buttons/TabSelector'
import Button from '../buttons/Button'
import { simulatorContext } from '../../service/SimulatorContext'
import SimulatorDesktopMenu from './SimulatorDesktopMenu';
export default function SimulatorSubHeader() {
    const { handleAddKid, addKidDisabled, tab, setTab } = useContext(simulatorContext);

    const tabsOptions = [
        { label: "תרומה חודשית", id: "monthly-donation-button", onClick: () => { setTab(0) } },
        { label: "החזר חודשי", id: "monthly-return-button", onClick: () => { setTab(1) } }
    ]

    return (
        <>
            <div className="frame-tab-menu-wrapper">
                <TabSelector tabs={tabsOptions} current={tab} />
                {<Button size='small' className={(tab===0)? '':'mobile'} disabled={addKidDisabled} onClick={handleAddKid} icon="plus-circle" label='הוסף ילד/ה' />}
            </div>
            <SimulatorDesktopMenu/>
        </>
        
    )
}
