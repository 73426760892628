import { useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { v4 as uuidv4 } from 'uuid';
import './datePicker.css'
import { dateToString } from '../../service/dateUtil';

export default function DatePicker({ type, value, onChange, name, id, label, className = '', tooltip, required = false, readonly = false, style, children, ...other }) {

    const [openCalender, setOpenCalender] = useState(false)
    const [touched, setTouched] = useState(false)

    const dateRef = useRef()

    const handleClick = (e) => {
        e.preventDefault();
        setOpenCalender(true)
        dateRef.current.focus()
    }

    // Close Calender on Blur
    const handleBlur = (event) => {
        if (!dateRef.current.contains(event.relatedTarget))
            setOpenCalender(false);
    }

    const handleChange = (e) => {
        // setTouched(true)
        if (typeof (onChange) === 'function')
            onChange(e.target.value);
    }

    const handleCalenderChange = (e) => {
        if (typeof (onChange) === 'function')
            onChange(dateToString(e));
        setOpenCalender(false);
    }

    // Handle Dynamic element Id    
    id = useMemo(() => (id) ? id : uuidv4(), [id]);

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            // e.preventDefault();
            handleClick(e);
        }
    }

    return (
        <div className={`field-wrapper ${className}`} style={style}>
            <label htmlFor={id}>{(required) ? <>*&nbsp;{label}</> : <>{label}</>}
                {(tooltip) && <div role="tooltip" aria-label={name}  tool-tip={tooltip}><img src="./icons/question-mark-circle.svg" alt='?' /></div>}
            </label>
            <div className="input-wrapper">
                <input value={value} onBlur={(e) => { setTouched(true) }} onChange={handleChange} onDoubleClick={handleClick} autoComplete="off" type={type} name={name} id={id} required={required} readOnly={readonly} {...other} />
                {(type === 'date') && <img src="./icons/calendar.svg" alt='calender icon' onClick={handleClick} tabIndex={0} onKeyDown={handleKeyDown}/>}
            </div>
            {(touched && !value) && <div className='validation-message'>{value}</div>}
            <div className='calendar-wrapper' ref={dateRef} tabIndex={-1} onBlur={handleBlur}>
                {(openCalender) && <Calendar onChange={handleCalenderChange} value={value} calendarType={'hebrew'} />}
            </div>
            {children}

        </div>
    )
}


