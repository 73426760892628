import React from 'react'

export default function FrameSection({ children }) {
  return (
    <section className="frame-section max-content">
      {children}
    </section>

  )
}
