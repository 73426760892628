import { useContext } from 'react'
import InputRange from '../input/InputRange'
import { simulatorContext } from '../../service/SimulatorContext'

export default function SummaryTotalSlider({ id, label, units, value = 0, max = 0, showUnits = false ,onClick,...other} ) {

    const { HandleChangeSummaryDonationSlider } = useContext(simulatorContext)

    return (
        <div className="total-wrapper" id={id} >
            <div className="total-label" onClick={onClick}>
                <div>{label}</div>
                <div className="total-amount">
                    {(showUnits && units) ? <span className="units">({units} יח')</span> : <></>}
                    <span className="value">{value?.toLocaleString('he-IL')}</span>
                    <span className="shekel-mrk">₪</span>
                </div>
            </div>
            <InputRange summary={true} name="total-monthly-donation-range" max={max} value={units} onChange={HandleChangeSummaryDonationSlider} {...other} />
        </div>
    )
}
