
import { useLocation, Link } from "react-router-dom"

export default function Footer() {
    const location = useLocation()
    //Footer allways visible on main page (Wizard), by default Fotter hidden on mobile
    const style = (location.pathname === '/simulator') ? {} : { position: 'static', display: 'block' }

    return (
        <footer style={style} >
            <div className="footer-container max-content">
                <div className="logo-container">
                    <a href="https://glb.org.il/" target="_blank" rel="noreferrer" ><img className="logo-icon" alt='לוגו גמח' src="./images/logo.png" /></a>
                </div>
                <div className="links-wrapper">
                    {(location.pathname === '/registration') && <a href="/" target="_blank" >סימולטור תרומות והלוואות</a>}
                    <a href="https://glb.org.il" target="_blank" rel="noreferrer">אתר הגמ״ח המרכזי</a>
                    <Link to='/terms'>תקנון</Link>
                    <Link to='/privacyPolicy'>מדיניות פרטיות</Link>
                    <Link to='/accessibility'>נגישות</Link>
                    <div>© כל הזכויות שמורות לגמ"ח לבית בישראל</div>
                </div>
                <div className="social-icons">
                    <a href="tel://*8215" target="_blank" rel="noreferrer">
                        <img src="/icons/social-phone.svg" alt='Phone' />
                    </a>
                    {/* <a href="whatsapp" target="_blank" rel="noreferrer">
                        <img src="/icons/social-whatsapp.svg" alt='WhatsApp' />
                    </a> */}
                    <a href="mailto:glb@glb.org.il" target="_blank" rel="noreferrer">
                        <img src="/icons/social-mail.svg" alt='Email' />

                    </a>
                    {/* <a href="youtube" target="_blank" rel="noreferrer">
                        <img src="/icons/social-youtube.svg" alt='YouTube'/>
                    </a> */}
                </div>
                <div className="design-by">
                    Design by uniqui | development by Happyflow
                </div>
            </div>
        </footer>
    )
}

