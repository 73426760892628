import  { useContext, useState } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import TimelineGantt from '../TimelineGantt/TimelineGantt'

export default function MonthlyReturnTab({ className = '' }) {
    
    const [jewishDate,setJewishDate] = useState(false)
    
    const { tab, completedKids } = useContext(simulatorContext)
    
    return (
        <section id="monthly-return-wrapper" className={`${className} ${(tab !== 0) ? 'show-flex':''}`}>
            <div className="header-wrapper">
                <div  role="heading" aria-level="2" className='heading-h3'>החזר חודשי</div >
                <div className="date-format-selector">
                    <label htmlFor="heb-date"><input type="radio" name="date-lang" id="heb-date" checked={jewishDate} onChange={()=>setJewishDate(true)} />תאריך עברי</label>
                    <label htmlFor="eng-date"><input type="radio" name="date-lang" id="eng-date" checked={!jewishDate} onChange={()=>setJewishDate(false)} />תאריך לועזי</label>
                </div>
            </div>
            {(tab) && <TimelineGantt completedKids={completedKids} jewishDate={jewishDate } />}
        </section>
    )
}
