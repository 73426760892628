import React, { useContext } from 'react'
import './registrationCompleted.css'
import { appContext } from '../../service/AppContext'
import Button from '../buttons/Button';

export default function RegistrationCompleted() {

    const { navigate } = useContext(appContext);

    const handleNavigate = () => {
        navigate('/')
    }

    return (
        <div className='registration-completed'>
            <div className='completed-wrapper'>
                <img src="./icons/document-check.svg" alt='registration completed ✓' />
                <div  role="heading" aria-level="2" className='heading-h3'>הטופס נשלח בהצלחה</div>
                <p className='sub-header'>טופס בקשת ההצטרפות לגמ״ח הושלמה בהצלחה. נציג שלנו יצור איתכם קשר בהקדם לצורך השלמת התהליך ואישורו הסופי</p>
            </div>
            <Button label='חזרה לעמוד הבית' onClick={handleNavigate} />
        </div>
    )
}
