import React, { useState } from 'react'
import './bankStandingOrder.css'
import BankInfoForm from '../bankInfoForm/BankInfoForm'
import ManualBankInfoForm from '../bankInfoForm/ManualBankInfoForm'
import { useFetch } from '../../service/useFetch'


export default function StandingOrderSelector({onCompleted}) {

    const [openingMethod, setOpeningMethod] = useState('')
    const { data: banks } = useFetch('/banks');

    // fields-flex
    return (
        <div className='bank-standing-order'>
            <div className='method-button-wrapper'>
                <label htmlFor="bank-standing-method">
                    <input type="radio" name="opening-method" id="bank-standing-method" checked={openingMethod === 'atBank'} onChange={(e) => setOpeningMethod('atBank')} />פתיחת הרשאה באופן עצמאי בבנק
                </label>
                {(openingMethod === 'atBank') &&
                    <BankInfoForm onCompleted={onCompleted} banks={banks } />
                }
            </div>
            <div className='method-button-wrapper'>
                <label htmlFor="form-method">
                    <input type="radio" name="opening-method" id="form-method" checked={openingMethod === 'byForm'} onChange={(e) => setOpeningMethod('byForm')} />מילוי הרשאה בטופס ידני
                </label>
                {(openingMethod === 'byForm') &&
                    <ManualBankInfoForm onCompleted={onCompleted} />
                }
            </div>
        </div>
    )
}


