
export function timeLineAccumulator(completedKids) {

    let units = [];
    let donations = [];
    let loans = [];
    let dates = [];
    let ranges = [];
    let today = new Date();
    let firstDayThisMonth = getFirstDateInMonth(today);

    completedKids.forEach(function (unit) {
        let donation = toDonationRange(unit);
        let loan = toLoanRange(unit);
        donations.push(donation);
        loans.push(loan);
        units.push({ ...unit, donation,loan, familyLoan: toOriginalLoanRange(unit) })
    });

    addExtraLoanForFamilyGrant(completedKids);

    [...donations, ...loans].forEach(function (donation) {
        dates.push(donation.startDate);
        dates.push(donation.endDate);
    });

    let uniqueDates = dates
        .map(s => s.getTime())
        .filter((s, i, a) => a.indexOf(s) === i)
        .map(s => new Date(s));

    uniqueDates.sort(function (a, b) {
        return a - b;
    });

    let currentRange;
    let previousRange = {};
    uniqueDates.forEach(function (date, index) {
        currentRange = {
            "startDate": date,
            "monthlyDonation": donations.reduce(getSumAmount(date), 0),
            "activeDonations": donations.reduce(getCount(date), 0),
            "monthlyReturn": loans.reduce(getSumAmount(date), 0),
            "actualMonthlyReturn": loans.reduce(getActualSumAmount(date), 0),
            "activeLoans": loans.reduce(getCount(date), 0)
        }
        if (index > 0) {
            previousRange["endDate"] = addMonths(date, -1);
            ranges.push(previousRange);
        }
        previousRange = currentRange
    });


    function getSumAmount(date) {

        function sumAmount(total, range) {
            if (date >= range.startDate && date < range.endDate) {
                total += range.amount
            }
            return parseFloat(total.toFixed(2));
        }
        return sumAmount;
    }

    function getActualSumAmount(date) {

        function sumAmount(total, range) {
            if (date >= range.startDate && date < range.endDate) {
                total += range.actualAmount
            }
            return parseFloat(total.toFixed(2));;
        }
        return sumAmount;
    }

    function getCount(date) {

        function sumAmount(total, range) {
            if (date >= range.startDate && date < range.endDate) {
                total += 1;
            }
            return total;
        }
        return sumAmount;
    }

    function toDonationRange(unit) {
        return {
            "startDate": firstDayThisMonth,
            "endDate": calculateDonationEndDate(unit),
            "amount": unit.plan.monthlyPay * parseInt(unit.units)
        }
    }

    function toLoanRange(unit) {
        let firstDate = calculateLoanStartDate(unit);
        return {
            "startDate": firstDate,
            "endDate": calculateLoanEndDate(unit),
            "amount": unit.plan.monthlyReturn * parseInt(unit.units),
            "actualAmount": unit.plan.monthlyReturn * parseInt(unit.units)
        }
    }
    function toOriginalLoanRange(unit) {
        let firstDate = calculateLoanStartDate(unit);
        return {
            "startDate": firstDate,
            "endDate": calculateOriginalLoanEndDate(unit),
            "amount": unit.plan.monthlyReturn * parseInt(unit.units),
            "actualAmount": unit.plan.monthlyReturn * parseInt(unit.units)
        }
    }

    function addExtraLoanForFamilyGrant(cLoans) {
        cLoans.forEach(function (loan) {
            let originalLoanEndDate = calculateLoanEndDate(loan);
            if (loan.familyGrant) {
                loans.push({
                    "startDate": addMonths(originalLoanEndDate, 1),
                    "endDate": calculateOriginalLoanEndDate(loan),
                    "amount": loan.plan.monthlyReturn * parseInt(loan.units),
                    "actualAmount": 0
                })
            }
        });
    }

    function calculateDonationEndDate(unit) {
        return getFirstDateInMonth(addMonths(new Date(), unit.plan.monthsSave));
    }

    function calculateLoanStartDate(unit) {
        let yearUntilMarriage = parseInt(unit.marriage) - parseInt(unit.age);
        let yearUntilFinishDonation = unit.plan.monthsSave / 12;
        let years = Math.max(yearUntilMarriage, yearUntilFinishDonation);
        return getFirstDateInMonth(addMonths(new Date(), years * 12));
    }

    function calculateLoanEndDate(unit) {
        let firstDate = calculateLoanStartDate(unit);
        let familyGrant = (unit.familyGrant) ? unit.familyGrant / unit.units : unit.plan.return
        let loanMonths = Math.round((unit.plan.loan - familyGrant) / unit.plan.monthlyReturn);
        return addMonths(firstDate, loanMonths);
    }

    function calculateOriginalLoanEndDate(unit) {
        let firstDate = calculateLoanStartDate(unit);
        let loanMonths = Math.round(unit.plan.loan / unit.plan.monthlyReturn);
        return addMonths(firstDate, loanMonths);
    }

    function addMonths(date, months) {
        return new Date(new Date(date).setMonth(date.getMonth() + months));
    }

    function getFirstDateInMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }
    return { ranges, donations, loans, units, dates }
}