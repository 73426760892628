import { useState, useEffect } from 'react';

function getStorageValue(key, defaultValue) {
  // getting stored value
  const storedObj = localStorage.getItem(key);
  if (storedObj) {
    let now = new Date()
    let obj = JSON.parse(storedObj);
    if (new Date(obj?.expire) >= now) {
      return obj?.value;
    }
    else
      localStorage.removeItem(key);
  }
  return defaultValue;
}


export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(getStorageValue(key, defaultValue));

  function attemptUseStoredValue(key) {
    // getting stored value
    const storedValue = getStorageValue(key, undefined);
    if (storedValue) {
      setValue(storedValue);
    }
  }

  useEffect(() => {
    if (key === undefined || key === '' || value === undefined) return;
    let now = new Date()
    let expire = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
    localStorage.setItem(key, JSON.stringify({ expire, value }));
  }, [value, key]);



  return [value, setValue, attemptUseStoredValue];
}

