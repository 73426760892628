import React from 'react'
import Popup from '../popup/Popup';
import Checkbox from '../input/Checkbox';
import Button from '../buttons/Button'
import SignaturePad from '../signaturePad/SignaturePad';

export default function InterestTermsPopup({ closePopup, onSign, signature, setSignature }) {


    return (
        <Popup closePopup={closePopup}>
            <div className="title-wrapper">
                <h3>תקנון בענייני ריביות</h3>
                <p className='sub-header'>הגמ"ח המרכזי שע"י עמותת גמ"ח לבית בישראל ע.ר. 580403533 (להלן: "הגמ"ח") הוא מיזם חסד המבוסס כלכלית לצורך סיוע לרבבות משפחות להשיא את צאצאיהם בכבוד.</p>
            </div>
            <div className='terms-popup'>
                <div className='popup-terms-container' tabIndex={0}>
                    <ol className='outer-list'>
                        <li>הצטרפות
                            <ol className='inner-list'>
                                <li>
                                    כל אזרח ישראלי המחזיק בחשבון בנק ישראלי, יכול להצטרף כחבר בגמ"ח באמצעות מילוי טופס הצטרפות המצורף לתקנון זה כנספח א' והפקדת תרומה חודשית בסכום קבוע בהתאם לכללי הגמ"ח (להלן: "התורם").
                                </li><li>
                                    יובהר כי ההצטרפות כחבר בגמ"ח אינה מהווה חברות בעמותת 'גמ"ח לבית בישראל' וההצטרפות הינה בהתאם להנחיות ועדת הרבנים של הגמ"ח ואשר מפורטות במסמך הוראות הלכתיות והיתר עסקה המצורף להסכם זה כנספח ב'.
                                </li><li>
                                    מחשש לאיסורי ריבית מודגש ומובהר כי הפקדת התרומה בגמ"ח הינה תרומה לכל דבר ועניין ואין התחייבות למתן הלוואה או מענק כלשהו.
                                </li><li>
                                    ההצטרפות לגמ"ח הינה באמצעות תרומה חודשית לקופת הגמ"ח, באמצעות אמצעי תשלום מסודר כגון הו"ק בנקאית לטובת הגמ"ח, או לחילופין העברת פרטי כרטיס אשראי על שם התורם לצורך העברת התרומה החודשית באופן שוטף.
                                </li><li>
                                    במקרים חריגים ובהתאם לשיקול דעת הנהלת הגמ"ח, תתאפשר הצטרפות באמצעות תשלום במזומן ו/או בהמחאות ו/או באמצעות פרטי כרטיס אשראי שאינם על שם התורם. יודגש שהדבר תלוי בשיקול דעת בלעדי של הנהלת הגמ"ח ויתאפשר אך ורק לאחר קבלת אישור מיוחד מהנהלת הגמ"ח.
                                </li><li>
                                    בהצטרפות לגמ"ח מתחייב התורם להפקדה חודשית מסודרת של התרומה ובאחריותו לוודא שהתרומות מופקדות כסדרן.
                                </li><li>
                                    בכל מקרה של שינוי באמצעי התשלום, מוטלת החובה על התורם לעדכן באופן מידי את הגמ"ח בשינוי ולהסדיר את אמצעי התשלום בהתאם.
                                </li><li>
                                    במקרה של הוצאות בגין עמלת חיוב חוזר או כל הוצאה אחרת שנגרמה בשל החזרת חיוב , התורם מתחייב לשלם את ההוצאה בהתאם לדרישת הגמ"ח.
                                </li><li>
                                    ההתקשרות בין הגמ"ח לתורם תתבצע באמצעות פרטי התורם שירשמו בטופס ההצטרפות אשר יאושר על ידי התורם ויאומת על ידי נציג הגמ"ח.
                                </li><li>
                                    הודעה באמצעות אחד או יותר מפרטי ההתקשרות המצוינים בטופס הפרטים מהווה אישור לקבלת ההודעה על ידי התורם. במקרה של הודעה בדואר ההודעה תחשב כנתקבלה בחלוף 3 ימים ממועד שליחתה.
                                </li><li>
                                    עדכון ושינוי פרטי התקשרות יתבצע אך ורק באמצעות הודעה כתובה שתועבר לגמ"ח על ידי התורם בעצמו או באמצעות כתובת דוא"ל המצוינת ככתובת הדוא"ל של התורם, בטופס ההצטרפות.
                                </li><li>
                                    ההצטרפות לגמ"ח הינה על דעת שבכל דין ודברים בין הצדדים הנושא יועבר לדיון בדין תורה בבית הדין של המועצה הדתית בירושלים (להלן: "הבורר המוסכם"). יובהר כי חתימה על הצטרפות לגמ"ח מהווה חתימה על הסכם בוררות בכל דין ודברים שיהיה בין הצדדים.
                                </li>
                            </ol>
                        </li>
                        <li>
                            תכניות הגמ"ח
                            <ol className='inner-list'>
                                <li>
                                    בהתאם לתוכנית הכלכלית של העמותה ובכפוף לנהלי הגמ"ח וכפי שיעודכנו מעת לעת, הפקדת תרומה חודשית קבועה (40 ₪ לחודש למשך 120 חודשים במסלול הבסיסי) לזכות של הבן או הבת של התורם (להלן: "יחידה"), תעניק זכאות עקרונית לבן או הבת לקבלת הלוואה בזמן נישואיהם בסכום שנקבע על ידי הנהלת הגמ"ח ובפריסת תשלומים נוחה של 100 תשלומים (40,000 ₪ בתשלומים של 400 ₪ לחודש במסלול הבסיסי) היחידות הינן בהתאם לתוכניות השונות המוצעות על ידי הגמ"ח ומפורטות בנספח ג' וכפי שיעודכנו מעת לעת על ידי הנהלת הגמ"ח.
                                </li><li>
                                    הזכאות העקרונית להלוואה הינה עבור הבן או הבת שלזכותם נרשמה היחידה (להלן: הלווה").מובהר ומודגש כי בכל מקרה הזכות העקרונית להלוואה אינה ניתנת למכירה או להעברה.
                                </li><li>
                                    ניתן להפקיד עבור מספר יחידות לכל בן או בת החל מגיל 3 או קודם לכך, סכום הזכאות העקרונית להלוואה יחושב בהתאם למספר היחידות של התורם, עבור הבן או הבת הנישאים.
                                </li><li>
                                    עבור כל יחידה יקבע תאריך משוער להגשת הבקשה עבור אותה יחידה (להלן: "תאריך יעוד"). תאריך היעוד יקבע בסביבות גיל 19 של הבן או הבת.
                                </li><li>
                                    מובהר כי בכל מקרה הזכאות להלוואה הינה החל מתקופת הנישואין בפועל. גם לאחר הנישואין ניתן יהיה לממש את הזכות להלוואה עבוד הבן או הבן לתקופה של עד 10 שנים מתאריך היעוד או הנישואין על פי המאוחר מבניהם.
                                </li><li>
                                    לצורך מימוש הזכאות הלווה יפנה לגמ"ח ויגיש בקשה להלוואה, בהתאם לכללים המפורטים בטפסי ההלוואה, המצורפים לתקנון זה כנספח ד'. יצוין כי תנאי ההלוואה והביטחונות עשויים להשתנות מעת לעת ובכל מקרה הלווה יצטרך לחתום על מסמכי ההלוואה כפי שיהיה מקובל באותה עת בגמ"ח.
                                </li><li>
                                    מובהר כי התרומה לגמ"ח המוגדרת כתרומה מזכה על פי חוק תהווה גבייה של הוצאות כלליות על ההלוואה, ככל שתינתן לזכות התורם או מי מטעמו, באופן בו סכום החוב או חלקו, יהיה צמוד למדד המחירים לצרכן, כפי שמפרסמת הלשכה המרכזית לסטטיסטיקה. המדד היסודי של ההלוואה הוא המדד הידוע ב-1 לחודש בו אושרה ההלוואה.
                                </li><li>
                                    יודגש כי תנאי ההלוואה והדרישה להעמדת הבטוחות אינה ממצה את זכות הגמ"ח לדרוש תנאים אחרים ו/או בטוחות נוספות בהתאם לשיקול דעת הנהלת הגמ"ח.
                                </li><li>
                                    כמו כן, בכל מקרה של חשש סביר לחוסר יכולת לפירעון ההלוואה הגמ"ח שומר לעצמו את הזכות שלא לאשר את בקשת ההלוואה.
                                </li><li>
                                    יודגש כי במקרה של אישור בקשת ההלוואה, סכום ההלוואה יועבר לחשבון הבנק של הלווה וגביית התשלומים להחזר ההלוואה תהיה גם היא מחשבון הבנק של הלווה.
                                </li><li>
                                    במקרים חריגים ניתן להגיש בקשה להפקדת ההלוואה או להעמדת אמצעי תשלום באמצעות חשבון בנק אחר. הדבר נתון לשיקול דעת בלעדי של הנהלת הגמ"ח.
                                </li>
                            </ol>
                        </li>
                        <li>
                            הקדמת הלוואות
                            <ol className='inner-list'>
                                <li>
                                    ניתן להגיש בקשה להנהלת הגמ"ח על מנת להקדים את מועד ההלוואה מתאריך היעוד שנקבע בשעת ההצטרפות למסלול.
                                </li><li>
                                    אישור הבקשה להקדמת ההלוואה נתון לשיקול דעת בלעדי של הנהלת הגמ"ח, שיכולה לקחת בחשבון את מועד הזמן שנותר עד לתאריך היעוד. על כל פנים, סירוב הבקשה אינו מצריך נימוק או הסבר מצד הנהלת הגמ"ח.
                                </li><li>
                                    ככל שתאושר הבקשה להקמת ההלוואה תבוטל הזכאות של הבן או הבת לקבלת ההלוואה בעת נישואיהם. כמו כן, במקרים מסוימים אישור ההקדמה ישנה את תנאי ההלוואה והמענקים של התוכנית אליה משויכת יחידת ההלוואה אותה מבקש התורם להקדים.
                                </li>
                            </ol>
                        </li>
                        <li>
                            מענק סיום ההלוואה
                            <ol className='inner-list'>
                                <li>
                                    כחלק מהתכנית הכלכלית ובהתאם למטרות העמותה בכוונת הגמ"ח לתת מענק סיוע מיוחד עם סיום תקופת פירעון ההלוואה. גובה המענק ותנאיו הינם בהתאם לסוג התוכניות השונות ובהתאם למדיניות הגמ"ח באותה עת.
                                </li><li>
                                    ניתן להגיש בקשה לקבלת מענק הסיום במהלך השנה האחרונה לפירעון יחידת ההלוואה והמענק יינתן לתורם או ללווה בהתאם לשיקול דעת הנהלת הגמ"ח ובהתחשב בבקשת המענק שהגיש התורם.
                                </li><li>
                                    יובהר ויודגש כי אין התחייבות כלשהי לאישור המענק מצד הנהלת הגמ"ח.
                                </li>
                            </ol>
                        </li>
                        <li>
                            ביטול החברות בגמ"ח
                            <ol className='inner-list'>
                                <li>
                                    ביטול החברות בגמ"ח תתאפשר באמצעות הודעה בכתב אותה יעביר התורם להנהלת הגמ"ח (להלן: "ביטול החברות").
                                </li><li>
                                    כל הודעה בכתב מהתורם, על ביטול החברות בגמ"ח, מהווה ויתור מוחלט ומידי על כל זכאות או הטבה כלפי התורם ו/או בני משפחתו.
                                </li><li>
                                    במקרה של ביטול החברות, התורם יכול להגיש בקשה לקבלת מענק בסכום אותו הפקיד כתרומה במשך השנים, בקיזוז החזר הוצאות לגמ"ח בסכום של 200 ₪, או בסכום אחר כפי שיעודכן מעת לעת על ידי הנהלת הגמ"ח, עבור כל יחידת הלוואה. (להלן: "מענק ביטול")
                                </li><li>
                                    מוסכם כי הסכום הנקוב כהחזר הוצאות עבור כל יחידה מהווה, החזר ההוצאות מינימלי המקובל על הצדדים ללא כל צורך בהוכחה לגבי ההוצאות.
                                </li><li>
                                    כמו כן, במידה ומסיבה כלשהי יהיו הוצאות חריגות הנוגעות ליחידות ההלוואה של התורם, הגמ"ח יוכל להשיב את ההוצאות שהוציא גם בסכום שיעלה על הסכום המוסכם בכפוף לפירוט מסודר על ההוצאות.
                                </li><li>
                                    מוסכם ומובהר כי הנהלת הגמ"ח תבחן את הבקשה למענק ביטול והדבר נתון לשקול דעתה הבלעדי וללא כל התחייבות לקבלת מענק הביטול.
                                </li><li>
                                    בכל מקרה של תקלה באמצעי התשלום של התורם, שהועבר לצורך הפקדת התרומה החודשית, התורם יקבל הודעה על התקלה בה הוא יידרש להסדיר את התקלה ולהשלים את הגביה החסרה. יובהר כי הגמ"ח אינו מחויב להודעות חוזרות על תקלה שחוזרת על עצמה.
                                </li><li>
                                    ככל שתקלה תחזור על עצמה ויצטברו שלוש תרומות חודשיות שלא התקבלו בגמ"ח, הגמ"ח יהיה רשאי להפסיק את הליכי הגבייה של התרומות ולשלוח התראה על ביטול החברות בגמ"ח.
                                </li><li>
                                    בחלוף חודשיים מהודעת ההתראה על ביטול החברות, ככל שלא יופקדו בגמ"ח כל התרומות החודשיות השוטפות בנוסף להסדרה של כלל החיובים, כולל השבת כספי עמלות ששולמו על ידי הגמ"ח בשל התקלות בגביה, הגמ"ח יהיה רשאי, ללא כל הודעה נוספת, לבטל את החברות בגמ"ח, בהתאם לתנאי ביטול החברות בגמ"ח ולא תהיה עוד זכאות כלשהי בנוגע ליחידות ההלוואה של התורם.
                                </li><li>
                                    במקרה של ביטול החברות בשל אי עמידה בתנאי התוכנית, הגמ"ח רשאי לאחד את סכומי התרומות שהופקדו ליחידות שלמות שיזכו את התורם בהלוואה בהתאם לתאריך היעוד המאוחר מבין המועדים של יחידות ההלוואה שברשותו.
                                </li><li>
                                    למען הסר ספק, יובהר כי במקרה שתאושר בקשה למענק ביטול, תבוטל לחלוטין הזכאות של התורם ו/או משפחתו.
                                </li><li>
                                    בכל מקרה של ביטול החברות, ניתן יהיה להגיש בקשה לחזרה לתוכנית המקורית והבקשה תיבחן בהתאם לנתונים של התוכניות והגילאים התקפים למועד בקשתו לחזרה לתוכניות.
                                </li><li>
                                    במקרה של ביטול חברות פעם נוספת, לאחר חזרה לתוכנית, סכום הקיזוז המוסכם, יעמוד על כפל הסכום המקוזז במענק ביטול יחידה.
                                </li>
                            </ol>
                        </li>
                        <li>
                            שינוים ועדכונים
                            <ol className='inner-list'>
                                <li>
                                    מעבר בין תוכניות הגמ"ח יתאפשר עד גיל שבו ניתן להצטרף, בכפוף למילוי טופס שדרוג. כל שינוי לאחר הגיל בו ניתן להצטרף לתכנית המבוקשת, ייבחן באופן פרטני על ידי הנהלת הגמ"ח.
                                </li><li>
                                    ניתן להעביר את היחידה מזכות של ילד מבוגר לזכות של ילד צעיר, אך לא ניתן להעביר את זכות היחידה לילד בגיל מבוגר יותר מהגיל של הזכאי המקורי ביחידה. במקרה של שינוי מילד לילד יעודכן תאריך היעוד להלוואה בהתאמה לגיל הילד.
                                </li><li>
                                    במקרה של שינוי במצב האישי של התורם ובני משפחתו, ניתן להגיש בקשה הדדית המוסכמת על שני בני הזוג על מנת לפצל את התיק לשני תורמים. ככל ולא ניתן להגיש בקשה מוסכמת, ניתן להגיש בקשה לעדכון פרטי התורם באמצעות אישור פרטני מבי"ד רבני או בית משפט על חלוקת התיק בגמ"ח.
                                </li><li>
                                    ככל שהגמ"ח יקבל הודעה רשמית על הליכי גירושין, ללא קבלת בקשה לחלוקה בהסכמה הדדית ולחילופין ללא קבלת אישור פרטני מרשות מוסמכת, הגמ"ח רשאי להפסיק באופן מיידי את הגבייה של התרומות ויפעל בהתאם להוראות התקנון בנוגע ליציאה מהתוכנית וביטול החברות. במקרה של ביטול החברות בנסיבות הללו, השבת כספי הפקדון בהתאם להוראות התקנון לגבי ביטול החברות, תתבצע לחשבון הבנק ממנו הופקדו התרומות החודשיות, או לחשבון אחר עליו יעבירו הצדדים הסכמה הדדית או הוראה פרטנית מרשות מוסמכת.
                                </li><li>
                                    במקרה של פטירת התורם ל"ע, ככל ובן הזוג חי הזכות לחברות בגמ"ח תועבר על שם בן הזוג והגבייה החודשית תמשיך להתבצע באותה מתכונת ללא שינוי בתנאים.
                                </li><li>
                                    ככל שתוגש בקשה לשינוי ברישום באמצעות צו ירושה או צוואה פרטנית הנוגעת לזכאות בגמ"ח, ניתן להגיש בקשה לשינוי החברות בגמ"ח בהתאם למסמכים והבקשה תישקל על ידי הנהלת הגמ"ח.
                                </li><li>
                                    העמותה שומרת לעצמה את הזכות לעדכן ולשנות את התקנון מעת לעת ובלבד שלא תהיה בכך פגיעה ממשית ובלתי סבירה בזכויות התורם או מי מטעמו.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className='approval-wrapper'>
                    <div className="title-wrapper">
                        <h4>אישור תקנון בענייני ריביות</h4>
                        <p className='sub-header'>הנני מאשר/ת שקראתי את התקנון ההצטרפות ואני מאשר/ת אותו</p>
                    </div>
                    <SignaturePad signature={signature} setSignature={setSignature} setSigned={onSign} />
                    <Button label='המשך' onClick={closePopup} />
                </div>
            </div>
        </Popup>

    )
}
