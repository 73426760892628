import React, { useContext } from 'react'
import { appContext } from './AppContext';
import { dateToAge, getDateAge } from './dateUtil';
import { formatNumber, getPersonIdValidation } from './inputUtil';
import { v4 as uuidv4 } from 'uuid';
import { initialKid } from './RegisrationContext'

export default function useUpdateKidCB({ donor = {}, kids, setKids }) {

    const { plans } = useContext(appContext);

    //handle add kid
    const addKid = () => {
        setKids(prev => [...prev, { ...initialKid, id: uuidv4() }])
    };


    //update kid fields via CallBack fn 
    const updateKidCB = (id, key) => {

        // Generate kid CallBack fn 
        return function callback(value) {
            // case field age
            if (key === 'age') {
                //update kid age & plan
                if (Number(value) === 0)
                    setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, preBorn: true, birthday: '', plan: null } : kid))
                else {
                    //update kid age & plan
                    let autoPlan = plans?.filter(plan => !plan?.familyPlan).find(plan => Number(plan?.age) >= value)
                    if (autoPlan && plans?.filter(plan => !plan?.familyPlan).filter(plan => Number(plan?.age) === autoPlan.age).length === 1) {
                        //update kid age & plan
                        setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, preBorn: false, birthday: (kid?.birthday) ? getDateAge(kid?.birthday, value) : '', plan: autoPlan } : kid))
                        return
                    }
                }
                setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, preBorn: false, birthday: (kid?.birthday) ? getDateAge(kid?.birthday, value) : '', plan: null } : kid))
                return
            }

            // case field plan
            if (key === 'plan') {
                //update plan based on plan route id
                setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, plan: plans?.find(plan => plan?.route === Number(value)) } : kid))
                return
            }
            if (key === 'preBorn') {
                //update plan based on plan route id
                if (value)
                    setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, age: 0, birthday: '', plan: null } : kid))
                else
                    setKids(prev => prev.map(kid => (kid.id === id) ? (kid.birthday) ? { ...kid, [key]: value, age: dateToAge(kid.birthday), plan: null } : { ...kid, [key]: value, age: null, plan: null } : kid))
                return
            }
            if (key === 'birthday') {
                //update plan based on plan route id
                let age = dateToAge(value)
                let autoPlan = plans?.filter(plan => !plan?.familyPlan).find(plan => Number(plan?.age) >= age)
                if (autoPlan && plans?.filter(plan => !plan?.familyPlan).filter(plan => Number(plan?.age) === autoPlan.age).length === 1) {
                    //update kid age & plan
                    setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, age, plan: autoPlan } : kid))
                    return
                }
                // debugger
                if (age === 0)
                    setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, age, preBorn: true, plan: null } : kid))
                else
                    setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, age, plan: null } : kid))
                return
            }
            if (key === 'personId') {
                value = formatNumber(value);
                let personIdValidation = getPersonIdValidation(value)
                if (!personIdValidation.invalid && (donor?.personId === value || kids?.some(kid => kid.personId === value))) {
                    personIdValidation = { invalid: true, errorText: 'ת.ז כבר בשימוש' }
                }
                setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value, personIdValidation } : kid))
            }
            //update Key:value (including age case no suitable plan)
            else
                setKids(prev => prev.map(kid => (kid.id === id) ? { ...kid, [key]: value } : kid))
        }
    }

    //handle remove kid
    const removeKid = (id) => {
        setKids(prev => prev.filter(kid => (kid.id !== id)))
    }



    return { addKid, updateKidCB, removeKid }

}
