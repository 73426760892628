import SummaryItemDetailsRow from './SummaryItemDetailsRow'
import { useState } from 'react'
export default function SummaryItemDonation({ kid }) {
    const [minimize, setMinimze] = useState(true)

    const { name, plan, age, marriage, units, grantFirstUnit } = kid;

    const monthsWait = (marriage - age) * 12 - plan?.monthsSave;

    const grantType = (grantFirstUnit) ? "מענק מלא על היחידה הראשונה" : "רגיל - בסיום ההלוואה";

    const handleMinimize = (e) => {
        setMinimze(prev => !prev)
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            setMinimze(prev => !prev)

        }
    }

    return (
        <div className="summary-item" onClick={handleMinimize} tabIndex={0} onKeyDown={handleKeyDown}>
            <div className="summary-item-wrapper" >
                <div className="summary-item-header">
                    <span>{name}
                        {/* {(familyGrant) ? <span className='recive-grant-tag' >מימוש מענק משפחתי</span> : <></>} */}
                    </span>
                    <div className="item-value-wrapper">
                        <span className="value"><span className="shekel-mrk">₪</span>{plan?.monthlyPay * Number(units)}</span>
                        <span className="label">תרומה חודשית</span>
                    </div>
                </div>
                <img className={`item-minimize ${(minimize) && 'minimized'}`} src="./icons/chevron-up.svg" alt='⌃' />
            </div>
            <div className="summary-item-details">
                <SummaryItemDetailsRow label="חודשי תרומה" type="text" value={plan?.monthsSave} />
                {/* <SummaryItemDetailsRow label="שנות תרומה" type="text" value={Math.round(plan?.monthsSave / 12)} /> */}
                <SummaryItemDetailsRow label="סך התרומה" value={plan?.totalPay * units} />
                <SummaryItemDetailsRow label="זמן המתנה מסיום תרומה עד לקבלת הלוואה" type="text" value={(monthsWait > 0) ? monthsWait : 0} >חודשים</SummaryItemDetailsRow>
                <SummaryItemDetailsRow label="החזר חודשי" value={plan?.monthlyReturn * units} />
                <SummaryItemDetailsRow label="גובה הלוואה" value={plan?.loan * units} />
                {/* <SummaryItemDetailsRow label="סוג המענק" type="text" value={grantType} /> */}
                {/* <SummaryItemDetailsRow label="גובה מענק" type='percent' value={Math.round(plan.return *100 / plan.totalPay)} /> */}
                < SummaryItemDetailsRow label="סכום מענק" value={plan?.return * units} />
            </div>
        </div>
    )
}
