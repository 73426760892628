import { useContext } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import ExportCsv from '../exportCsv/ExportCsv'
import ExportPdf from '../exportPdf/ExportPdf'

export default function SimulatorDesktopMenu() {
    const { handleResetKids } = useContext(simulatorContext)

    const handleKeyDown = (e) => {
        if (typeof (handleResetKids) !== 'function') return
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            handleResetKids();
        }
    }

    return (
        <div className="cards-menu desktop"  >
            <div className={`menu-items align-center `} >
                <div role='button' className="align-center" onClick={handleResetKids} tabIndex={0} onKeyDown={handleKeyDown}><img src="./icons/trash.svg" alt='trash can' /><span style={{ color: "var(--neutrals-neutral-900, #000204)" }}>נקה טופס</span></div>
                <ExportCsv />
                <ExportPdf />
            </div>
        </div>
    )
}
