import { v4 as uuidv4 } from 'uuid';
import {     useMemo, useRef, useState } from 'react';
import './fileUpload.css'


export default function FileUpload({ id, label, className = '', name, required = false, multiple = false, readonly = false, style, errorText, ...other }) {
    // Local State Managment
    const [fileslist, setFileslist] = useState([])
    const fileRef = useRef()

    const handleChange = (e) => {

        setFileslist([...e.target.files])
    }

    const handleRemoveFile = (fileName) => {
        return (e) => {
            const dt = new DataTransfer()
            let key = Number(Object.keys(fileRef.current.files).find(key => fileRef.current.files[key].name === fileName))
            for (let i = 0; i < fileRef.current.files.length; i++) {
                if (key !== i)
                    dt.items.add(fileRef.current.files[i])
            }
            fileRef.current.files = dt.files
            setFileslist(prev => prev.filter(file => file.name !== fileName))
        }
    }

    // Handle Dynamic element Id    
    id = useMemo(() => (id) ? id : uuidv4(), [id]);

    const handleClick = (e) => {
        if (!fileslist.length)
            fileRef.current?.click()
    }

    const handleKeyDown = (e) => {
        if ([13, 32].includes(e.keyCode)) {
            handleClick(e)
        }
    }

    return (
        <div className={`file-upload field-wrapper ${className}`} style={style}>
            <label htmlFor={id}>{label}
            </label>
            <div className="input-wrapper" onClick={handleClick} tabIndex={0} onKeyDown={handleKeyDown} >
                <input type='file' ref={fileRef} id={id} required={required} readOnly={readonly} name={name} {...other} onChange={handleChange} multiple={multiple} />

                {(!fileslist.length) ?
                    <div className='input-files' >
                        <img src="./icons/attach.svg" alt='attachment' />
                        <div className='input-files-desciption'>
                            <p className='input-header'><u>בחרו את המסמך הרצוי או גררו אותו לכאן</u></p>
                            <p className='input-info'>ניתן להעלות קבצים מסוג PNG / JPEG / PDFעד משקל 25MB</p>
                        </div>
                    </div>
                    :
                    <div className='files-list'>
                        {fileslist?.map(file => <FileItem key={file.name} name={file.name} handleRemoveFile={handleRemoveFile(file.name)} />)}
                    </div>
                }
            </div>
        </div >
    )
}




export function FileItem({ name, handleRemoveFile }) {

    const handleKeyDown = (e) => {
        if (typeof (handleRemoveFile) !== 'function') return
        if ([13, 32].includes(e.keyCode)) {
            e.preventDefault();
            handleRemoveFile(e);
        }
    }

    return (
        <div className='attachment'>
            <img className="radio-img" src="./icons/radio-done.svg" alt='הושלם' />
            {name}
            <div className='bar' >
                <div className='progress-bar' />
            </div>
            <img className='remove-file' src="./icons/x-mark.svg" alt='הסרת קובץ' width={24} onClick={handleRemoveFile} tabIndex={0} onKeyDown={handleKeyDown} />

        </div>)
}
