import ChildCard from '../simulatorChildCard/SimulatorChildCard';
import Disclaimer from '../../components/simulator/Disclaimer';
import { useContext } from 'react';
import { simulatorContext } from '../../service/SimulatorContext';
import Button from '../buttons/Button';
import useUpdateKidCB from '../../service/useUpdateKidCB';


export default function MonthlyDonationTab({ className = '' }) {

  const { kids,  addKidDisabled, handleAddKid, tab, } = useContext(simulatorContext);


  const { updateKidCB, removeKid } = useUpdateKidCB(useContext(simulatorContext))



  //Child Cards to render with dynamic plans options 
  const childCards = kids?.map((kid, index) => <ChildCard key={kid.id} index={index} {...kid} updateCB={updateKidCB} removeKid={removeKid} />)

  return (
    <section id="cards-wrapper" className={`${className} ${(tab !== 0) ? 'hidden' : ''}`}>
      {childCards}
      {(kids?.length > 2) && < Button size='small' className='mobile' disabled={addKidDisabled} onClick={handleAddKid} icon="plus-circle" label='הוסף ילד/ה' />}

      <Disclaimer />
    </section>
  )
}
