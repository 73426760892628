import { useState, createContext, useEffect, useRef } from 'react'
import { useFetch } from './useFetch';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import plans from './db.json'
export const appContext = createContext()

export default function AppContext({ children }) {

    const [searchParams] = useSearchParams();
    const [urlAgent, setUrlAgent] = useState({
        mainAgent: searchParams.get('mainAgent') || '',
        agent: searchParams.get('agent') || '',
        enabledBirthDay: (searchParams.get('bd_enabled') === '1') ? true : false,
        owner: searchParams.get('owner') || '',
        lead: searchParams.get('lead') || ''
    })

    const [user, setUser] = useState({ firstName: '', lastName: '', email: '', phone: '', personId: '' });

    const toastsRef = useRef()

    const { fetchOptions, data, isPending, error, setFetch } = useFetch();

    const navigate = useNavigate();

    // try to load lead data
    useEffect(() => {
        if (urlAgent.lead) {
            setFetch({ route: '/lead?id=' + urlAgent.lead })
            if (toastsRef.current)
                toast.update(toastsRef.current, { isLoading: true, render: 'טעינת נתונים, אנא המתן', rtl: true })        
            else
                toastsRef.current = toast.loading("טעינת נתונים, אנא המתן", { position: toast.POSITION.TOP_CENTER, rtl: true });
        }
    }, [urlAgent.lead, setFetch])

    // Save user lead Id 
    useEffect(() => {
        if (data?.lead) { setUser(data.lead); toast.done(toastsRef.current)}
        if (data?.unitRequest) { setUser(data.unitRequest); }
        if (data?.leadId) { setUser(prev => { return { ...prev, id: data.leadId } }); toast.done(toastsRef.current)}
    }, [data])

    // Handle Fetch Errors
    useEffect(() => {
        if (error && !isPending) {
            if ('msg' in error) {
                toast.error(error.msg, { position: toast.POSITION.TOP_CENTER });
                return
            }
            if (fetchOptions.route?.startsWith('/lead?id=')) {
                if (toastsRef.current)
                    toast.update(toastsRef.current, { isLoading: false, type: toast.TYPE.ERROR, render: 'טעינת נתונים נכשלה', autoClose: 5000 })
                else
                    toast.error("טעינת נתונים נכשלה", { position: toast.POSITION.TOP_CENTER });
                setUrlAgent(prev => ({ ...prev, lead: null }))
                return
            }
            if (['/lead', '/unit-request', '/bank-info', '/credit-card-info'].includes(fetchOptions.route)) {
                toast.error("שמירת נתונים נכשלה", { position: toast.POSITION.TOP_CENTER });
                return
            }
            toast.error("שגיאה בחיבור לשרת", { position: toast.POSITION.TOP_CENTER });
        }

    }, [fetchOptions.route, error, isPending])

    // Fetch plans from Backend
    const { data: plans } = useFetch('/plans');

    const value = { user, setUser, data, error, isPending, setFetch, navigate, plans, fetchOptions, urlAgent };

    return (
        <appContext.Provider value={value}>
            {children}
        </appContext.Provider >
    )
}
