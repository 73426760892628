import React, { useContext, useRef, useState } from 'react'
import { formatNumber } from '../../service/inputUtil';
import Input from '../input/Input';
import Select from '../select/Select';
import Button from '../buttons/Button';
import style from './CreditCardForm.module.css';
import { baseStyle, focusStyle, invalidStyle } from './pciStyle';
import useTokenEx from '../../service/useTokenEx';
import { appContext } from '../../service/AppContext';


export default function CreditCardForm({ onCompleted }) {

    const [cardDetails, setCardDetails] = useState({ cardNumber: '', expires: '', cvv: '', owner: '', ownerId: '', bilingDay: '', bilingDate: '' })

    const { user, setFetch } = useContext(appContext)

    const { cardMessage, cvvMessage, TokenExFormElement } = useTokenEx({ styles: { baseStyle, focusStyle, invalidStyle } })

    const formRef=useRef()

    const setCardDetailsCB = (key) => {
        if (key === 'cardNumber') return setCardNumber;
        if (key === 'expires') return setExpires;
        if (key === 'bilingDay') return setBilingDay;
        if (['cvv', 'ownerId'].includes(key)) return setNumber;
        return updateCardDetailsKey

        function setCardNumber(value) {
            value = formatNumber(value, { maxLength: 16, partLength: 4, repeat: true })
            updateCardDetailsKey(value)
        }
        function setNumber(value) {
            value = formatNumber(value)
            updateCardDetailsKey(value)
        }

        function setExpires(value) {
            value = formatNumber(value, { maxLength: 4, partLength: 2, separator: '/' })
            updateCardDetailsKey(value)
        }
        function setBilingDay(value) {
            let now = new Date();
            let today = now.getDate()
            if (Number(value) > today)
                setCardDetails(prev => { return { ...prev, [key]: value, bilingDate: new Date(now.setDate(value)).toISOString().slice(0, 10) } })
            else
                setCardDetails(prev => { return { ...prev, [key]: value, bilingDate: new Date(now.getFullYear(), now.getMonth() + 1, Number(value) + 1).toISOString().slice(0, 10) } })

        }

        function updateCardDetailsKey(value) {
            setCardDetails(prev => { return { ...prev, [key]: value } })
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const formElement = formRef.current;
        const isValid = formElement.checkValidity();

        formElement.classList.add('submitted');

        const firstInvalidField = formElement.querySelector(":invalid");
        firstInvalidField?.focus();

        if (isValid) {
            const dataObject = new FormData(formElement);
            if (user?.id)
                dataObject.append('id', user?.id);
            setFetch({
                route: '/credit-card-info', options: {
                    method: 'POST',
                    body: dataObject
                }
            })
            if (typeof (onCompleted) === 'function')
                onCompleted(e);
        }

    }

    return (
        <form ref={formRef} className='credit-card-details'  noValidate>
            <div className='credit-card-fields'>
                <div className='fields-flex'>
                    <TokenExFormElement />
                    <div id="tokenExIframeDiv" className={style.pciFieldWrapper} >
                        <label>* מספר כרטיס אשראי</label>
                        {(!!cardMessage) && <div className='validation-message'>{cardMessage}</div>}
                    </div>
                </div>
                <div className='fields-flex'>
                    <Input value={cardDetails.expires} className='card-expires' name="expire" onChange={setCardDetailsCB('expires')} label='תוקף' required autoComplete="cc-exp" pattern="^([0-9]{2}(/)){1}[0-9]{2}$" errorText='תוקף לא תקין' placeholder='mm/yy' />
                    <div id="CvvTextbox" className={style.pciCvvFieldWrapper} >
                        <label>* cvv</label>
                        {(!!cvvMessage) && <div className='validation-message'>{cvvMessage}</div>}
                    </div>
                </div>
                <div className='fields-flex'>
                    <Input value={cardDetails.owner} className='card-owner' name='card-owner' onChange={setCardDetailsCB('owner')} label='שם בעל הכרטיס' required autoComplete="cc-name" />
                    <Input value={cardDetails.ownerId} className='card-ownerId' name='card-owner-id' onChange={setCardDetailsCB('ownerId')} label='תעודת זהות של בעל הכרטיס' pattern="[0-9]{9}" required errorText='תעודת זהות לא חוקית' maxlength={9} />
                    <Select value={cardDetails.bilingDay} className="biling-day" name='biling-day' onChange={setCardDetailsCB('bilingDay')} label='תאריך בחודש לחיוב' required placeholder='בחרו יום בחודש' options={Array(31).fill().map((_, i) => i + 1)} />
                    <Input type="date" value={cardDetails.bilingDate} className="biling-date" label='תאריך ראשון לחיוב' placeholder='dd/mm/yy' disabled />
                </div>
            </div>
            <Button type='button' onClick={handleSubmit} label='אישור וביצוע התשלום' />
        </form>
    )
}
