
export default function Button({ id, className, label, children, disabled, onClick, icon, size, color, ...other }) {

  let dynamicClassName = (className) ? className : '';
  if (size && size === 'small')
    dynamicClassName += ' small-button align-center';
  if (color)
    dynamicClassName += ' ' + color;

  let dynamicIcon = <></>
  if (icon === 'check-circle')
    dynamicIcon = <img src="./icons/check-circle.svg" alt='✓' />
  else if (icon === 'plus-circle')
    dynamicIcon = <img src="./icons/plus-circle.svg" alt='+' />
  if (color === 'white-button' && icon === 'plus-circle')
    dynamicIcon = <img src="./icons/plus-circle-blue.svg" alt='+' />
  if (disabled && color === 'white-button' && icon === 'plus-circle')
    dynamicIcon = <img src="./icons/plus-circle-gray.svg" alt='+' />

  return (
    <button id={id} className={`button ${dynamicClassName}`} disabled={disabled} onClick={onClick} {...other} >{dynamicIcon}{label}</button>
  )
}
