import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';

function SignaturePad({ signature, setSigned, setSignature }) {

    const canvasRef = useRef()

    useEffect(() => {
        if (canvasRef.current && canvasRef.current?.isEmpty()) {
            let ctx = canvasRef.current.getCanvas().getContext("2d");
            let width = ctx.canvas.getBoundingClientRect().width
            let height = ctx.canvas.getBoundingClientRect().height
            ctx.strokeStyle = '#D9DDEC'
            ctx.beginPath();
            ctx.moveTo(40, height - 20);
            ctx.lineTo(width - 40, height - 20);
            ctx.stroke();
        }
        if (canvasRef.current && canvasRef.current?.isEmpty() && signature)
            canvasRef.current?.fromDataURL(signature);

    },)

    const handleGenerate = () => {
        if (typeof (setSigned) === 'function')
            setSigned(true);
        if (typeof (setSignature) === 'function')
            setSignature(canvasRef.current?.getSignaturePad().toDataURL('image/png'))
    }

    return (
        <div className="sig-container" >
            <SignatureCanvas
                canvasProps={{ className: 'sigCanvas' }}
                ref={canvasRef}
                onEnd={handleGenerate}
                backgroundColor={'#F8FAFC'}

            />
            <p className='buttom-text'>חתימה דיגיטלית</p>
        </div>


    )
}
export default SignaturePad;