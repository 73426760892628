export function dateToString(date) {
    return date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString().padStart(2, 0) + "-" + date.getDate().toString().padStart(2, 0)
}

export function isValidDate(date) {
    if (typeof (date) === 'string')
        return (dateToString(new Date(date)) === date)
}

export function dateToAge(date) {

    if (!isValidDate(date)) return
    var birthday = new Date(date);
    //calculate difference from current date in time    
    var ms_def = new Date().setHours(0, -(new Date().getTimezoneOffset()), 0) - birthday.getTime();
    //convert the calculated difference in date format  
    var date_delta = new Date(ms_def);

    //extract year from date      
    var years = date_delta.getFullYear() - 1970;
    var months = date_delta.getMonth();
    var days = date_delta.getDate() - 1
    
    if (years < 0)
        return 0
    var age = Math.abs(years);
    // console.log(`age: ${age} , Years:${years}, Months:${months}, days:${days}`)
    
    if (age === 0 && (months < 6 || (months === 6 && days === 0)))
        return 0.5

    if (months > 0 || days > 1) age++
    return age
}

export function getDateAge(date, age) {
    if (age === 0.5) {
        let newDate = Date.now() - 15780000000;
        return dateToString(newDate)
    }
    if (!date || dateToAge(date) < 1) {
        let newDate = new Date();
        newDate.setYear(newDate.getFullYear() - Number(age))
        return dateToString(newDate)

    }

    let oldAge = dateToAge(date)
    if (oldAge >= 1) {
        let newDate = new Date(date);
        newDate.setYear(newDate.getFullYear() - Number(age) + oldAge)
        return dateToString(newDate)
    }

}