// import "./styles.css";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import ChartTooltip from "./ChartTooltip";

export default function ReturnsChart({ ranges, label }) {

    const chartData = [];
    let sumPayments = 0;
    let countMonths = 0;

    ranges.forEach(range => {
        let startYear = range.startDate.getFullYear() % 100;
        let month = range.startDate.getMonth();
        let endYear = range.endDate.getFullYear() % 100;
        let endMonth = range.endDate.getMonth();
        let value = range.monthlyDonation + range.monthlyReturn;
        for (let year = startYear; year <= endYear; year++) {
            let lastMonth = (year === endYear) ? endMonth : 11;
            while (month <= lastMonth) {
                chartData.push({ year, month, value })
                sumPayments += value;
                if (value) countMonths++;
                month++;
            }
            month = 0;
        }
    });

    const avgPayment = (Math.round((sumPayments * 10 / countMonths)) / 10).toLocaleString('he-IL');

    const tickStyle = {
        fontFamily: 'SimplerPro',
        fontSize: 10,
        color: 'var(--neutrals-neutral-700-text, #495157)',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%'
    }
    return (
        <div className="graph" dir="ltr">
            <div className="graph-header" dir="rtl">
                <div className="graph-value">
                    <span className="shekel-mrk">₪</span>{avgPayment}
                </div>
                <div className="graph-label">{label}</div>
            </div>
            <ResponsiveContainer height={184} width='100%'>
                <AreaChart data={chartData} margin={{ top: 24, right: 16, left: 16, bottom: 24 }}>
                    <defs>
                        <linearGradient id="gradiantColorFill" >
                            <stop stopColor="#407BFF" stopOpacity="0.8" />
                            <stop offset="1" stopColor="#407BFF" stopOpacity="0.5" />
                        </linearGradient>
                    </defs>
                    <CartesianGrid orizontal={true} vertical={false} />
                    <XAxis dataKey="year" tickFormatter={tick => "'" + tick} interval={59} tickLine={false} axisLine={false} tick={tickStyle} />
                    <YAxis type="number"
                        tickFormatter={data => data.toLocaleString('he-IL')}
                        tickLine={false}
                        axisLine={false}
                        tick={tickStyle}
                        tickCount={7}
                        domain={[0, dataMax => {
                            let digits = (Math.round(dataMax).toString().length) - 1
                            return (Math.round(dataMax / (10 ** digits)) + 1) * (10 ** digits)
                        }]} />
                    <Area type="basis" dataKey="value" stroke="var(--brand-blue-500, #3B82F6)" stroke-linecap="round" fill="url(#gradiantColorFill)" strokeWidth={1.5} activeDot={<circle r="3" fill="#172554" stroke="#172554" />} />
                    <Tooltip cursor={{ stroke: 'var(--brand-blue-900-bg, #172554)', strokeDasharray: "3 3", strokeWidth: 1 }} content={<ChartTooltip />} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
