
// import './registration.css';

import { useEffect } from 'react';
import Registration from '../../components/registration/Registration';
import RegistrationContex from '../../service/RegisrationContext';
export default function RegistrationPage() {

  useEffect(() => {
    const prevTitle= document.title
    document.title = 'הצטרפות לגמ"ח המרכזי'
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <RegistrationContex>
      <Registration />
    </RegistrationContex>


  )
}
