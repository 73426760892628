import SummaryItemDetailsRow from './SummaryItemDetailsRow';
import { useState } from 'react';

import { monthName } from "../../service/SimulatorContext";

export default function SummaryItemReturn({ startDate, endDate, monthlyDonation, monthlyReturn, activeLoans, activeDonations, amount }) {
    const [minimize, setMinimze] = useState(true)

    const handleMinimize = (e) => {
        setMinimze(prev => !prev)
    }

    let startText = monthName[startDate?.getMonth()] + '\xa0' + startDate?.getFullYear();
    let endText = monthName[endDate?.getMonth()] + '\xa0' + endDate?.getFullYear();


    return (
        <div className="summary-item" onClick={handleMinimize}>
            <div className="summary-item-wrapper" >
                <div className="summary-item-header">
                    <span>{startText}&nbsp;-&nbsp;{endText}</span>
                    <div className="item-value-wrapper">
                        <span className="value"><span className="shekel-mrk">₪</span>{monthlyDonation + monthlyReturn}</span>
                        <span className="label">החזר חודשי</span>
                    </div>
                </div>
                <img className={`item-minimize ${(minimize) && 'minimized'}`} src="./icons/chevron-up.svg" alt='⌃' />
            </div>
            <div className="summary-item-details">
                <SummaryItemDetailsRow label="תרומות פעילות" type="text" value={activeDonations + '/' + amount} />
                <SummaryItemDetailsRow label="החזר חודשי" value={monthlyDonation} />
                <SummaryItemDetailsRow label="הלוואות פעילות" type="text" value={activeLoans + '/' + amount} />
                <SummaryItemDetailsRow label="החזר חודשי" value={monthlyReturn} />
            </div>
        </div>
    )
}
