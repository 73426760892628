import  { useContext } from 'react'
import { simulatorContext } from '../../service/SimulatorContext'
import TotalRow from './TotalRow';
import SummaryItemDonation from './SummaryItemDonation';
import GrantsChart from '../charts/GrantsChart';
// import FamilyGrantsChart from '../charts/FamilyGrantsChart';
export default function SummaryDontationTab({ className = '' }) {
    const { tab, completedKids, summaryKids } = useContext(simulatorContext)
    const grantPercent = Math.round(summaryKids.totalReturn * 100 / summaryKids.totalDonation);

    // const chartLabel =  `סך הכל מענקים (${grantPercent}%)`

    return (
        <div className={`${className} ${(tab === 0) ? '' : 'hidden'}`} id="donation-loan-tab">
            <div className="total-row-wrapper">
                <TotalRow label='סה״כ תרומה' value={summaryKids?.totalDonation} />
                <TotalRow id="total-returns-row" label='סה״כ מענקים'  value={summaryKids?.totalReturn} />
            </div>
            <GrantsChart
                    completedKids={completedKids}
                    totalGrant={summaryKids?.totalReturn}
                    label={'סך הכל מענקים'} />

            {/* <!---------- summary items -------------> */}
            {completedKids?.map((kid) =>
                    <SummaryItemDonation
                        key={kid.id}
                        kid={kid} />
                )}
        </div>
    )
}
