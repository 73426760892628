import React from 'react'
import './singleFrame.css'

export default function SingleFrame({ label, value, currency = false ,endFix}) {

    let dynamicValue= (currency) ? '₪' + value?.toLocaleString('he-IL') : value?.toLocaleString('he-IL')
    dynamicValue += (endFix) ? endFix : '';

    return (
        <div className='single-frame' >
            <div className='frame-lable'>{label}</div>
            <div className='frame-value'>{dynamicValue}</div>
        </div>
    )
}
